import Icon from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Collapse, Divider, Form, FormInstance, Menu, Row, Space } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import eventApi from '../../../../apis/EventApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Event } from '../../../../model/Entities';
import { payments } from '../../../../model/Types';
import { ReactComponent as arrowBackSvg } from '../../../../resources/images/ico-arrowBack.svg';
import { ReactComponent as backSvg } from '../../../../resources/images/ico-back.svg';
import { ReactComponent as step1Svg } from '../../../../resources/images/ico-1.svg';
import { ReactComponent as step2Svg } from '../../../../resources/images/ico-2.svg';
import { ReactComponent as step3Svg } from '../../../../resources/images/ico-3.svg';
import { ReactComponent as step4Svg } from '../../../../resources/images/ico-4.svg';
import { ReactComponent as bankSvg } from '../../../../resources/images/paymentBank.svg';
import { ReactComponent as cardSvg } from '../../../../resources/images/paymentCard.svg';
import styles from './WaiversPage.module.scss';

const { Panel } = Collapse;

class WaiversPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const event = await eventApi.getEvent(+this.props.match.params.id);
            this.setState({ event });
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (!desktop) {
            return (
                <div className={styles.header}>
                    <Link to={`/events/${+this.props.match.params.id}/summary`}>
                        <Button
                            type="text"
                            size="small"
                            className={styles.back}
                            icon={<Icon component={arrowBackSvg} />}
                        ></Button>
                    </Link>

                    <div className={styles.mobileHeaderBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <h1>
                        <FormattedMessage id="events.event.waivers.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="events.event.waivers.description" />
                    </p>
                </div>
            );
        } else {
            return (
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <h1>
                        <FormattedMessage id="events.event.waivers.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="events.event.waivers.description" />
                    </p>
                </div>
            );
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { loading, event } = this.state;

        const paymentsOptions = payments.map((payment) => <FormattedMessage id={payment} />);

        return (
            <div className={styles.form}>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <div className={styles.collapseArea}>
                        <Row gutter={[28, 0]}>
                            <Col xs={24} md={24}>
                                <Collapse defaultActiveKey={['0']} className={styles.collapse}>
                                    <Panel
                                        header={
                                            <Checkbox>
                                                {<FormattedMessage id="events.event.waivers.waiverRequirements" />}
                                            </Checkbox>
                                        }
                                        key="1"
                                    >
                                        <p>Requirements</p>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row gutter={[28, 0]}>
                            <Col xs={24} md={24}>
                                <Collapse defaultActiveKey={['0']} className={styles.collapse}>
                                    <Panel
                                        header={
                                            <Checkbox>
                                                {<FormattedMessage id="events.event.waivers.agreementStatement1" />}
                                            </Checkbox>
                                        }
                                        key="1"
                                    >
                                        <p>Agreement Statement</p>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row gutter={[28, 0]}>
                            <Col xs={24} md={24}>
                                <Collapse defaultActiveKey={['0']} className={styles.collapse}>
                                    <Panel
                                        header={
                                            <Checkbox>
                                                {<FormattedMessage id="events.event.waivers.agreementStatement2" />}
                                            </Checkbox>
                                        }
                                        key="1"
                                    >
                                        <p>Agreement Statement</p>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Row gutter={[28, 0]}>
                                <Col xs={24} md={24}>
                                    <Collapse defaultActiveKey={['1']} className={styles.collapse}>
                                        <Panel
                                            header={
                                                <Checkbox>
                                                    {<FormattedMessage id="events.event.waivers.eventPrice" />}
                                                </Checkbox>
                                            }
                                            key="1"
                                        >
                                            <p>Event Price</p>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </Form.Item>
                    </div>

                    <Row gutter={[28, 0]}>
                        <div>
                            <h2>
                                <FormattedMessage id="events.event.waivers.paymentMethods" />
                            </h2>
                        </div>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} sm={12} md={10} lg={8}>
                            <Card className={styles.bank} cover={<Icon component={bankSvg} />}>
                                <Card.Meta className={styles.title} title={paymentsOptions[0]} />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={10} lg={8}>
                            <Card className={styles.card} cover={<Icon component={cardSvg} />}>
                                <Card.Meta className={styles.title} title={paymentsOptions[1]} />
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Form.Item name="recaptcha">
                                <p className={styles.disclaimer}>
                                    <FormattedMessage id="events.event.waivers.disclaimer" />
                                </p>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item className="buttons">
                        <Space size={15}>
                            <Link to={`/events/${event?.id}/confirmation`}>
                                <Button type="primary" htmlType="submit" size="large" loading={loading}>
                                    <FormattedMessage id="events.event.waivers.submitRegistration" tagName="span" />
                                </Button>
                            </Link>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { event } = this.state;
        return (
            <div className={styles.header}>
                <Menu.ItemGroup>
                    <Menu.Item key="event-dashboard" icon={<Icon component={backSvg} />}>
                        <Link to={`/events/${event?.id}/child-profile`}>
                            <FormattedMessage id="events.event.back" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className={styles.divider} />

                <Menu.ItemGroup
                    key="event-register-menu"
                    title={!collapsed && <FormattedMessage id="events.event.registration" />}
                >
                    <Menu.Item key="event-register" icon={<Icon component={step1Svg} />}>
                        <Link to={`/events/${event?.id}/register`}>
                            <FormattedMessage id="events.event.parentProfile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-child" icon={<Icon component={step2Svg} />}>
                        <Link to={`/events/${event?.id}/child-profile`}>
                            <FormattedMessage id="events.event.childProfile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-payments" icon={<Icon component={step3Svg} />}>
                        <Link to={`/events/${event?.id}/waivers`}>
                            <FormattedMessage id="events.event.payments" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-confirmation" icon={<Icon component={step4Svg} />}>
                        <Link to={`/events/${event?.id}/confirmation`}>
                            <FormattedMessage id="events.event.confirmation" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </div>
        );
    };

    render() {
        return (
            <LayoutComponent
                page="event-payments"
                menu={this.renderMenu}
                content={this.renderContent}
                header={this.renderHeader}
            />
        );
    }
}
export default injectIntl(WaiversPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    event?: Event;
    loading?: boolean;
}
