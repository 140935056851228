import Icon from '@ant-design/icons';
import { Button, Divider, FormInstance, Menu } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import eventApi from '../../../../apis/EventApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Event } from '../../../../model/Entities';
import { ReactComponent as arrowBackSvg } from '../../../../resources/images/ico-arrowBack.svg';
import { ReactComponent as backSvg } from '../../../../resources/images/ico-back.svg';
import { ReactComponent as step1Svg } from '../../../../resources/images/ico-1.svg';
import { ReactComponent as step2Svg } from '../../../../resources/images/ico-2.svg';
import { ReactComponent as step3Svg } from '../../../../resources/images/ico-3.svg';
import { ReactComponent as step4Svg } from '../../../../resources/images/ico-4.svg';
import styles from './ConfirmationPage.module.scss';

class ConfirmationPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const event = await eventApi.getEvent(+this.props.match.params.id);
            this.setState({ event });
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (!desktop) {
            return (
                <div className={styles.header}>
                    <Link to={`/events/${+this.props.match.params.id}/summary`}>
                        <Button
                            type="text"
                            size="small"
                            className={styles.back}
                            icon={<Icon component={arrowBackSvg} />}
                        ></Button>
                    </Link>

                    <div className={styles.mobileHeaderBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <h1>
                        <FormattedMessage id="events.event.confirmation.title" />
                    </h1>
                </div>
            );
        } else {
            return (
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <h1>
                        <FormattedMessage id="events.event.confirmation.title" />
                    </h1>
                </div>
            );
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { loading } = this.state;

        return (
            <>
                <Button type="primary" htmlType="submit" size="large" loading={loading}>
                    <FormattedMessage id="events.event.confirmation.confirm" tagName="span" />
                </Button>
            </>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { event } = this.state;
        return (
            <div className={styles.header}>
                <Menu.ItemGroup>
                    <Menu.Item key="event-dashboard" icon={<Icon component={backSvg} />}>
                        <Link to={`/events/${event?.id}/waivers`}>
                            <FormattedMessage id="events.event.back" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className={styles.divider} />

                <Menu.ItemGroup
                    key="event-register-menu"
                    title={!collapsed && <FormattedMessage id="events.event.registration" />}
                >
                    <Menu.Item key="event-register" icon={<Icon component={step1Svg} />}>
                        <Link to={`/events/${event?.id}/register`}>
                            <FormattedMessage id="events.event.parentProfile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-child" icon={<Icon component={step2Svg} />}>
                        <Link to={`/events/${event?.id}/child-profile`}>
                            <FormattedMessage id="events.event.childProfile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-payments" icon={<Icon component={step3Svg} />}>
                        <Link to={`/events/${event?.id}/waivers`}>
                            <FormattedMessage id="events.event.payments" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-confirmation" icon={<Icon component={step4Svg} />}>
                        <Link to={`/events/${event?.id}/confirmation`}>
                            <FormattedMessage id="events.event.confirmation" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </div>
        );
    };

    render() {
        return (
            <LayoutComponent
                page="event-confirmation"
                menu={this.renderMenu}
                content={this.renderContent}
                header={this.renderHeader}
            />
        );
    }
}
export default injectIntl(ConfirmationPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    event?: Event;
    loading?: boolean;
}
