import Icon from '@ant-design/icons';
import { Button, Col, DatePicker, Form, FormInstance, Input, Row, Space } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import notificationService from '../../../services/NotificationService';
import { ReactComponent as SaveSvg } from '../../../resources/images/save.svg';

class EventCreationPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    onCancel = async () => {
        this.formRef.current?.resetFields();
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { loading } = this.state;
        return (
            <Form ref={this.formRef} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="event.creation.name" />}
                            name="name"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="event.creation.groupFees" />}
                            name="groupFees"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="event.creation.registrationDates" />}
                            name="registrationDates"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="event.creation.sponsor" />}
                            name="sponsor"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="event.creation.celebrationDate" />}
                            name="celebrationDate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker style={{ width: 395 }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[28, 0]}>
                    <Space size={15}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            icon={<Icon component={SaveSvg} />}
                            loading={loading}
                        >
                            <FormattedMessage id="event.creation.save" />
                        </Button>
                        <Button size="large" onClick={() => this.onCancel()}>
                            <FormattedMessage id="event.creation.cancel" />
                        </Button>
                    </Space>
                </Row>
            </Form>
        );
    };

    render() {
        return <LayoutComponent page="event-creation" content={this.renderContent} />;
    }
}
export default injectIntl(EventCreationPage);

interface Props extends WrappedComponentProps {}

interface State {
    loading?: boolean;
}
