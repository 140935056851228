import { Page } from '../model/Elements';
import { Division } from '../model/Entities';
import apiAxios from './CustomAxios';

class DivisionApi {
    list = async (): Promise<Division[]> => {
        const response = await apiAxios.get<Page<Division>>('/v1/divisions', {});

        return response.data.items;
    };

    get = async (divisionId: number): Promise<Division> => {
        const response = await apiAxios.get<Division>(`/v1/divisions/${divisionId}`);
        return response.data;
    };

    create = async (division: Division): Promise<Division> => {
        const response = await apiAxios.post<Division>('/v1/divisions', division);
        return response.data;
    };

    update = async (division: Division): Promise<Division> => {
        const response = await apiAxios.put<Division>(`/v1/divisions/${division.id}`, division);
        return response.data;
    };

    delete = async (division: Division): Promise<void> => {
        await apiAxios.delete(`/v1/divisions/${division.id}`);
    };
}

const divisionApi: DivisionApi = new DivisionApi();
export default divisionApi;
