import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import organizationApi from '../../../apis/OrganizationApi';
import teamApi from '../../../apis/TeamApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { LegacyPage } from '../../../model/Elements';
import { Organization, Team } from '../../../model/Entities';
import notificationService from '../../../services/NotificationService';
import styles from './TeamsPage.module.scss';

class TeamsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            //const organizations = await organizationApi.listAll();
            const teams = await teamApi.listAll();
            const teamsPage = await teamApi.list(500, 10);
            //const filteredRosters = [...rosters];

            this.setState({ teams, teamsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, sorter: any) => {
        try {
            this.setState({ loading: true });
            const organizations = await organizationApi.listAll();
            const teams = this.getTeams(organizations);
            this.setState({ teams });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            this.setState({ loading: false });
        }
    };

    /*search = async () => {
        const { teamsPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = teamsPage!.size;
            const sortField = teamsPage!.sort.field!;
            const sortOrder = teamsPage!.sort.order!;
            const teamsPageNew = await teamApi.listAll(0, pageSize, sortField, sortOrder);
            this.setState({ teamsPage: teamsPageNew });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            this.setState({ loading: false });
        }
    };*/

    private getTeams = (organizations: Organization[]): Team[] => {
        const teams: Team[] = [];
        organizations
            .filter((organization) => organization.teams)
            .flatMap((organization) => Array.prototype.push.apply(teams, organization.teams!));

        const distinctTeams: Team[] = teams.filter((team, i, arr) => arr.findIndex((t) => t.name === team.name) === i);

        return distinctTeams;
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                <div className={styles.table}>{this.renderTable()}</div>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        return (
            <div className={styles.toolbar}>
                <h1>
                    <FormattedMessage id="team.title" />
                </h1>
                <Link to="/admin/teams/new">
                    <Tooltip title={<FormattedMessage id="teams.tooltip" />}>
                        <Button type="primary" size="large" className={styles.add} icon={<PlusOutlined />}>
                            <FormattedMessage id="teams.add" />
                        </Button>
                    </Tooltip>
                </Link>
            </div>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { loading, teamsPage } = this.state;
        const items = teamsPage ? teamsPage.content : [];
        const columns: ColumnsType<Team> = [
            {
                title: <FormattedMessage id="team.name" />,
                dataIndex: 'name',
                key: 'name',
                //sorter: true,
                render: (value: string, team: Team) => <Link to={`/admin/teams/${team.id}`}>{value}</Link>,
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                //pagination={tableService.createLegacyPagination(teamsPage)}
                rowKey="id"
                onChange={this.list}
                //sortDirections={['ascend']}
                //showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent page="admin-teams" content={this.renderContent} />;
    }
}
export default injectIntl(TeamsPage);

interface Props extends WrappedComponentProps {}

interface State {
    teams?: Team[];
    teamsPage?: LegacyPage<Team>;
    loading?: boolean;
}
