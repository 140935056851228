import { Col, Row } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import notificationService from '../../services/NotificationService';

class CodesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <div>
                            <h3>
                                <FormattedMessage id="admin.codes.coachCode" />
                            </h3>
                        </div>
                    </Col>
                    <Col xs={24} md={8}>
                        <div>HGTI</div>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <div>
                            <h3>
                                <FormattedMessage id="admin.codes.athleteCode" />
                            </h3>
                        </div>
                    </Col>
                    <Col xs={24} md={8}>
                        <div>GSTS</div>
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        return <LayoutComponent page="codes" content={this.renderContent} />;
    }
}
export default injectIntl(CodesPage);

interface Props extends WrappedComponentProps {}

interface State {
    loading?: boolean;
}
