export const authorities = ['ROLE_ADMIN', 'ROLE_ORGANIZATION', 'ROLE_PLAYER'] as const;
export type AuthorityType = typeof authorities[number];

export type PageType =
    | 'leaderboard'
    | 'combine'
    | 'events'
    | 'rosters'
    | 'player-profile'
    | 'player-data'
    | 'player-media'
    | 'player-edit'
    | 'player-code'
    | 'colleges'
    | 'colleges-public'
    | 'tutorials'
    | 'add-team'
    | 'codes'
    | 'settings'
    | 'signout'
    | 'signin'
    | 'roster'
    | 'event'
    | 'event-register'
    | 'event-child'
    | 'event-payments'
    | 'event-confirmation'
    | 'event-creation'
    | 'events-management'
    | 'events-media'
    | 'admin-organizations'
    | 'add-organization'
    | 'admin-teams'
    | 'edit-team'
    | 'admin-players'
    | 'edit-player'
    | 'admin-colleges'
    | 'edit-college'
    | 'admin-users'
    | 'edit-user'
    | 'admin-coaches'
    | 'edit-coach'
    | 'admin-divisions'
    | 'edit-division'
    | 'admin-awards'
    | 'edit-award';

export const sports = ['baseball', 'softball', 'soccer', 'football', 'basketball'] as const;
export type SportType = typeof sports[number];

export const seasons = ['first', 'second', 'third'] as const;
export type SeasonsType = typeof seasons[number];

export const locations = ['san francisco', 'los angeles', 'new york'] as const;
export type LocationsType = typeof locations[number];

export const levels = ['prospectTeam', 'varsity', 'juniorVarsity', 'freshmen', '18u', 'kindergarten'] as const;
export type LevelsType = typeof levels[number];

export const positions = ['defense', 'forward'] as const;
export type PositionType = typeof positions[number];

export const grades = ['5th', '6th', '7th'] as const;
export type GradeType = typeof grades[number];

export const years = ['2021', '2022', '2023', '2024'] as const;
export type YearsType = typeof years[number];

export const genders = ['Male', 'Female'] as const;
export type GenderType = typeof genders[number];

export const medias = ['photo', 'video'] as const;
export type MediaType = typeof medias[number];

export const displays = ['list', 'cards'] as const;
export type DisplayType = typeof displays[number];

export const payments = ['bankAccount', 'creditCard'] as const;
export type PaymentType = typeof payments[number];

export const states = ['Alabama', 'Oklahoma'] as const;
export type StateType = typeof states[number];

export const divisions = ['NCAA Division I', 'NCAA Division II'] as const;
export type DivisionType = typeof divisions[number];

export const users = ['Coach', 'Athlete'] as const;
export type UserType = typeof users[number];

export const awards = ['Athletic', 'Academic'] as const;
export type AwardType = typeof awards[number];
