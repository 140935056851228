import Icon from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import teamApi from '../../../../apis/TeamApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Team } from '../../../../model/Entities';
import { ReactComponent as SaveSvg } from '../../../../resources/images/ico-save.svg';
import { ReactComponent as DeleteSvg } from '../../../../resources/images/ico-delete.svg';
import styles from './TeamPage.module.scss';

class TeamPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            if (this.props.match.params.id === 'new') {
                await this.new();
            } else {
                await this.get();
            }
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };

    new = async () => {
        const team: Team = {
            name: '',
        };

        this.setState({ team });
        this.formRef.current!.setFieldsValue(team);
    };

    get = async () => {
        const team = await teamApi.get(this.props.match.params.id);
        this.setState({ team });
        this.formRef.current!.setFieldsValue(team);
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { team, loading } = this.state;

        return (
            <>
                {this.renderToolbar()}
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="team.edit.name" />}
                                name="name"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} placeholder={team?.name} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className={styles.buttons}>
                        <Link to="/admin/teams">
                            <Button size="large" className={styles.back}>
                                <FormattedMessage id="team.edit.back" />
                            </Button>
                        </Link>
                        {this.props.match.params.id !== 'new' && (
                            <Button size="large" icon={<Icon component={DeleteSvg} />} className={styles.delete}>
                                <FormattedMessage id="team.edit.delete" />
                            </Button>
                        )}

                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className={styles.save}
                            icon={<Icon component={SaveSvg} />}
                            loading={loading}
                        >
                            <FormattedMessage id="team.save" />
                        </Button>
                    </div>
                </Form>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        if (this.props.match.params.id === 'new') {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="team.addtitle" />
                    </h1>
                </div>
            );
        } else {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="team.edittitle" />
                    </h1>
                </div>
            );
        }
    };

    render() {
        return <LayoutComponent page="edit-team" content={this.renderContent} />;
    }
}
export default injectIntl(TeamPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    team?: Team;
    loading?: boolean;
}
