import { Form, FormInstance, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { levels, sports } from '../../model/Types';
import notificationService from '../../services/NotificationService';

class AddTeamPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            this.setState({ loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel?.bind(this);
    };

    update = async () => {
        try {
            this.setState({ loading: true });
            const values = await this.formRef.current?.validateFields();
            await this.props.onUpdate(values);
            this.formRef.current?.resetFields();
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        //const { loading } = this.state;

        const sportsOptions = sports.map((sport) => (
            <Select.Option key={sport} value={sport}>
                <FormattedMessage id={sport} />
            </Select.Option>
        ));

        const levelsOptions = levels.map((level) => (
            <Select.Option key={level} value={level}>
                <FormattedMessage id={level} />
            </Select.Option>
        ));

        return (
            <Modal
                title={<FormattedMessage id="admin.addTeam.add" />}
                visible={true}
                onCancel={this.cancel}
                onOk={this.update}
                //closable={true}
                //maskClosable={true}
                //destroyOnClose={true}
                //okButtonProps={{ loading: false }}
                cancelButtonProps={{ disabled: false }}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="admin.addTeam.sport" />}
                        name="sport"
                        rules={[{ message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select size="large">{sportsOptions}</Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="admin.addTeam.level" />}
                        name="level"
                        rules={[{ message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select size="large">{levelsOptions}</Select>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="admin.addTeam.name" />} name="kids">
                        <Input maxLength={100} size="large" />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    render() {
        return <LayoutComponent page="add-team" content={this.renderContent} />;
    }
}
export default injectIntl(AddTeamPage);

interface Props extends WrappedComponentProps {
    visible?: boolean;
    onUpdate: (values: any) => Promise<void>;
    onCancel: () => void;
    mandatory?: boolean;
}

interface State {
    loading?: boolean;
}
