import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import playerApi from '../../../apis/PlayerApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { LegacyPage } from '../../../model/Elements';
import { Player } from '../../../model/Entities';
import notificationService from '../../../services/NotificationService';
import tableService from '../../../services/TableService';
import styles from './PlayersPage.module.scss';

class PlayersPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const playersPage = await playerApi.list(0, tableService.pageSize, 'id', true);
            this.setState({ playersPage });
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, sorter: any) => {
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const playersPage = await playerApi.list(page - 1, pageSize, sortField, sortOrder);
            this.setState({ playersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            this.setState({ loading: false });
        }
    };

    search = async () => {
        const { playersPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = playersPage!.size;
            const sortField = playersPage!.sort.field!;
            const sortOrder = playersPage!.sort.order!;
            const playersPageNew = await playerApi.list(0, pageSize, sortField, sortOrder);
            this.setState({ playersPage: playersPageNew });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                <div className={styles.table}>
                    {this.renderTable()}
                </div>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        return (
            <div className={styles.toolbar}>
                <h1><FormattedMessage id="player.title" /></h1>
                <Link to="/admin/players/new">
                    <Tooltip title={<FormattedMessage id="player.tooltip" />}>
                        <Button type="primary" size="large" className={styles.add} icon={<PlusOutlined />}>
                            <FormattedMessage id="player.add" />
                        </Button>
                    </Tooltip>
                </Link>
            </div>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { playersPage, loading } = this.state;
        const items = playersPage ? playersPage.content : [];
        const columns: ColumnsType<Player> = [
            {
                title: <FormattedMessage id="player.firstName" />,
                dataIndex: 'firstName',
                key: 'firstName',
                sorter: true,
                render: (value: string, player: Player) => <Link to={`/admin/players/${player.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="player.lastName" />,
                dataIndex: 'lastName',
                key: 'lastName',
                sorter: true,
                render: (value: string, player: Player) => <Link to={`/admin/players/${player.id}`}>{value}</Link>,
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createLegacyPagination(playersPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent page="admin-players" content={this.renderContent} />;
    }
}
export default injectIntl(PlayersPage);

interface Props extends WrappedComponentProps {}

interface State {
    playersPage?: LegacyPage<Player>;
    loading?: boolean;
}
