import Icon from '@ant-design/icons';
import { Button, Divider, Menu } from 'antd';
import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import eventApi from '../../../apis/EventApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Event } from '../../../model/Entities';
import { ReactComponent as arrowBackSvg } from '../../../resources/images/ico-arrowBack.svg';
import { ReactComponent as backSvg } from '../../../resources/images/ico-back.svg';
import { ReactComponent as softballSvg } from '../../../resources/images/sportSoftball.svg';
import { ReactComponent as eventSummarySvg } from '../../../resources/images/ico-summary.svg';
import { ReactComponent as eventLeaderboardSvg } from '../../../resources/images/ico-leaderboard.svg';
import { ReactComponent as locationSvg } from '../../../resources/images/ico-location.svg';
import { ReactComponent as shareSvg } from '../../../resources/images/ico-share.svg';
import styles from './EventPage.module.scss';

class EventPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const event = await eventApi.getEvent(+this.props.match.params.id);
            this.setState({ event });
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return (
                <div className={styles.header}>
                    <p>
                        <FormattedMessage id="events.event.summary.registration" />
                    </p>
                    <Button icon={<Icon component={shareSvg} />}>
                        <FormattedMessage id="events.event.summary.share" />
                    </Button>
                </div>
            );
        } else {
            return (
                <div className={styles.header}>
                    <Link to={`/events`}>
                        <Button
                            type="text"
                            size="small"
                            className={styles.back}
                            icon={<Icon component={arrowBackSvg} />}
                        ></Button>
                    </Link>
                    <p>
                        <FormattedMessage id="events.event.summary.registration" />
                    </p>
                    <Button type="ghost" className={styles.share} icon={<Icon component={shareSvg} />}></Button>
                </div>
            );
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { event } = this.state;

        return (
            <div className={styles.eventDetails}>
                <div className={styles.top}>
                    <div className={styles.details}>
                        <div className={styles.category}>
                            <Icon component={softballSvg} />
                            Softball - Female
                        </div>
                        <h1>{event?.name}</h1>
                        <ul>
                            <li>
                                <h2>
                                    <FormattedMessage id="player.events.registrationDate" />
                                </h2>
                                <p>{event?.registrationDates}</p>
                            </li>
                            <li>
                                <h2>
                                    <FormattedMessage id="player.events.groupFees" />
                                </h2>
                                <p>{event?.groupFees}</p>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.register}>
                        <div className={styles.sponsor}>
                            <div className={styles.logo}>{event?.sponsor}</div>
                            <div className={styles.date}>
                                <div>17</div>
                                <div>
                                    <p>Apr</p>
                                    <p>Sat, 6:00 PM</p>
                                </div>
                            </div>
                        </div>

                        <Button size="large" type="primary" shape="round">
                            <Link to={`/events/${event?.id}/register`}>
                                <FormattedMessage id="player.events.register" />
                            </Link>
                        </Button>
                    </div>
                </div>

                <div className={styles.more}>
                    <div className={styles.register}>
                        <Button size="large" type="primary" shape="round">
                            <Link to={`/events/${event?.id}/register`}>
                                <FormattedMessage id="player.events.register" />
                            </Link>
                        </Button>
                    </div>

                    <div className={styles.flex}>
                        <div className={styles.info}>
                            <h3>
                                <FormattedMessage id="events.event.summary.summary" />
                            </h3>
                            <div className={styles.desc}>
                                <FormattedMessage id="events.event.summary.summaryDescription" />
                            </div>

                            <h3>
                                <FormattedMessage id="events.event.summary.date" />
                            </h3>
                            <div className={styles.desc}>
                                <FormattedMessage id="events.event.summary.dateDescription" />
                            </div>

                            <h3>
                                <FormattedMessage id="events.event.summary.evaluation" />
                            </h3>
                            <div className={styles.desc}>
                                <FormattedMessage id="events.event.summary.evaluationDescription" />
                            </div>

                            <h3>
                                <FormattedMessage id="events.event.summary.receive" />
                            </h3>
                            <div className={styles.desc}>
                                <FormattedMessage id="events.event.summary.receiveDescription" />
                            </div>
                        </div>

                        <div className={styles.map}>
                            <h3>
                                <FormattedMessage id="events.event.summary.location" />
                            </h3>
                            <div className={styles.googleMaps}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: '' }}
                                    defaultCenter={{
                                        lat: 43.044,
                                        lng: -76.149,
                                    }}
                                    defaultZoom={17}
                                ></GoogleMapReact>
                            </div>
                            <p>1800 Broadway Streer, Unit 2C Buffalo, NY 14212</p>
                            <Button icon={<Icon component={locationSvg} />}>
                                {<FormattedMessage id="events.event.summary.getDirections" />}
                            </Button>

                            <h3>
                                <FormattedMessage id="events.event.summary.sponsor" />
                            </h3>
                            <p>
                                <a href="#sponsor">{event?.sponsor}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { event } = this.state;
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item key="event-dashboard" icon={<Icon component={backSvg} />}>
                        <Link to="/events">
                            <FormattedMessage id="events.event.backToEvents" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                <Menu.ItemGroup key="event-menu" title={!collapsed && <FormattedMessage id="events.event.info" />}>
                    <Menu.Item key="event" icon={<Icon component={eventSummarySvg} />}>
                        <Link to={`/events/${event?.id}/summary`}>
                            <FormattedMessage id="events.event.summary" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="leaderboard" icon={<Icon component={eventLeaderboardSvg} />}>
                        <FormattedMessage id="events.event.leaderboard" />
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    };

    render() {
        return (
            <LayoutComponent
                page="event"
                menu={this.renderMenu}
                content={this.renderContent}
                header={this.renderHeader}
            />
        );
    }
}
export default injectIntl(EventPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    event?: Event;
    loading?: boolean;
}
