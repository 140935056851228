import Icon from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, message, Row } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import userApi from '../../../../apis/UserApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { User } from '../../../../model/Entities';
import { ReactComponent as SaveSvg } from '../../../../resources/images/ico-save.svg';
import { ReactComponent as DeleteSvg } from '../../../../resources/images/ico-delete.svg';
import notificationService from '../../../../services/NotificationService';
import styles from './UserPage.module.scss';

class UserPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            if (this.props.match.params.id === 'new') {
                await this.new();
            } else {
                await this.get(this.props.match.params.id);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    new = async () => {
        const user: User = {
            profile: {
                givenName: '',
            },
        };

        this.setState({ user });
        this.formRef.current!.setFieldsValue(user);
    };

    get = async (id: string) => {
        const user = await userApi.get(id);

        this.setState({ user });
        this.formRef.current!.setFieldsValue(user);
    };

    save = async (values: any) => {
        try {
            this.setState({ loading: true });
            let user: User = Object.assign({}, this.state.user, values);
            user = user.id ? await userApi.update(user) : await userApi.create(user);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ user });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'user.status.duplicate' },
            ]);
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    delete = async () => {
        const { user } = this.state;
        try {
            this.setState({ loading: true });
            await userApi.delete(user!);
            this.props.history.push(`/admin/users`);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        return (
            <>
                {this.renderToolbar()}
                <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.givenName" />} name={['profile', 'givenName']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.gender" />} name={['profile', 'gender']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.email" />} name={['profile', 'email']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.phone" />} name={['profile', 'phone']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.birthday" />} name={['profile', 'birthday']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.weight" />} name={['profile', 'weight']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.height" />} name={['profile', 'height']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.facebook" />} name={['profile', 'facebook']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.gradYear" />} name={['profile', 'gradYear']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.instagram" />} name={['profile', 'instagram']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="user.parentName" />}
                                name={['profile', 'parentName']}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="user.parentEmail" />}
                                name={['profile', 'parentEmail']}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="user.parentPhone" />}
                                name={['profile', 'parentPhone']}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.school" />} name={['profile', 'school']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.snapchat" />} name={['profile', 'snapchat']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.twitter" />} name={['profile', 'twitter']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.zip" />} name={['profile', 'zip']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="user.grade" />} name={['profile', 'grade']}>
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="user.familyName" />}
                                name={['profile', 'familyName']}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className={styles.buttons}>
                        <Link to="/admin/users">
                            <Button size="large" className={styles.back}>
                                <FormattedMessage id="user.back" />
                            </Button>
                        </Link>
                        {this.props.match.params.id !== 'new' && (
                            <Button
                                size="large"
                                icon={<Icon component={DeleteSvg} />}
                                className={styles.delete}
                                onClick={() => this.delete()}
                            >
                                <FormattedMessage id="user.delete" />
                            </Button>
                        )}
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className={styles.save}
                            icon={<Icon component={SaveSvg} />}
                            loading={loading}
                        >
                            <FormattedMessage id="user.save" />
                        </Button>
                    </div>
                </Form>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        if (this.props.match.params.id === 'new') {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="users.addtitle" />
                    </h1>
                </div>
            );
        } else {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="users.edittitle" />
                    </h1>
                </div>
            );
        }
    };

    render() {
        return <LayoutComponent page="edit-user" content={this.renderContent} />;
    }
}
export default injectIntl(UserPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    user?: User;
    loading?: boolean;
}
