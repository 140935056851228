import { Page } from '../model/Elements';
import { College } from '../model/Entities';
import apiAxios from './CustomAxios';

class CollegeApi {
    list = async (limit: number, page: number, sortField?: string, sortOrder?: string): Promise<Page<College>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getSortField(sortField);
        const response = await apiAxios.get<Page<College>>('/v1/organizations', {
            params: { limit, page, [field]: order },
        });

        return response.data;
    };

    listAll = async (): Promise<College[]> => {
        return Promise.resolve(colleges);
    };

    get = async (collegeId?: number): Promise<College> => {
        const response = await apiAxios.get<College>(`/v1/organizations/${collegeId}`);
        return response.data;
    };

    create = async (college: College): Promise<College> => {
        const response = await apiAxios.post<College>('/v1/organizations', college);
        return response.data;
    };

    update = async (college: College): Promise<College> => {
        const response = await apiAxios.put<College>(`/v1/organizations/${college.id}`, college);
        return response.data;
    };

    delete = async (college: College): Promise<void> => {
        await apiAxios.delete(`/v1/organizations/${college.id}`);
    };

    private getSortField(sortField?: string): string {
        let field: string = 'sorting.School';
        if (sortField === 'school') {
            field = 'sorting.School';
        } else if (sortField === 'division') {
            field = 'sorting.Division';
        }
        return field;
    }
}

const collegeApi: CollegeApi = new CollegeApi();
export default collegeApi;

// mock data
const colleges: College[] = [...Array(4)].map((v, i) => ({
    id: i,
    name: `College ${i}`,
    division: 'NCAA Division I',
    state: 'Alabama',
    athleticWebsite: `College${i}.com`,
}));
