import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Modal, Row, Select, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../context/CustomContext';
import notificationService from '../../../services/NotificationService';

class AddMediaComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    update = async () => {
        try {
            this.setState({ loading: true });
            //const values = await this.formRef.current?.validateFields();
            //await this.props.onUpdate(values);
            this.formRef.current?.resetFields();
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.cancel}

                //onOk={this.update}
                //okButtonProps={{ loading: false }}
                //cancelButtonProps={{ disabled: false }}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row align="middle" gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item name="upload">
                                <Upload>
                                    <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />}></Button>
                                </Upload>
                                {<FormattedMessage id="player.media.upload" />}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={<FormattedMessage id="player.media.notes" />} name="notes">
                                <TextArea rows={4} />
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="player.media.metric" />} name="notes">
                                <Select placeholder={<FormattedMessage id="player.media.addMetric" />}></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(AddMediaComponent);

interface Props extends WrappedComponentProps {
    visible?: boolean;
    //onUpdate: (values: any) => Promise<void>;
    onCancel: () => void;
}

interface State {
    loading?: boolean;
}
