import Icon from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Menu,
    Row,
    Select,
    Space,
} from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import eventApi from '../../../../apis/EventApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Event } from '../../../../model/Entities';
import { genders } from '../../../../model/Types';
import { ReactComponent as arrowBackSvg } from '../../../../resources/images/ico-arrowBack.svg';
import { ReactComponent as backSvg } from '../../../../resources/images/ico-back.svg';
import { ReactComponent as step1Svg } from '../../../../resources/images/ico-1.svg';
import { ReactComponent as step2Svg } from '../../../../resources/images/ico-2.svg';
import { ReactComponent as step3Svg } from '../../../../resources/images/ico-3.svg';
import { ReactComponent as step4Svg } from '../../../../resources/images/ico-4.svg';
import styles from './ChildProfilePage.module.scss';

class ChildProfilePage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const event = await eventApi.getEvent(+this.props.match.params.id);
            this.setState({ event });
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (!desktop) {
            return (
                <div className={styles.header}>
                    <Link to={`/events/${+this.props.match.params.id}/summary`}>
                        <Button
                            type="text"
                            size="small"
                            className={styles.back}
                            icon={<Icon component={arrowBackSvg} />}
                        ></Button>
                    </Link>

                    <div className={styles.mobileHeaderBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <h1>
                        <FormattedMessage id="events.event.child.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="events.event.child.description" />
                    </p>
                </div>
            );
        } else {
            return (
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <h1>
                        <FormattedMessage id="events.event.child.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="events.event.child.description" />
                    </p>
                </div>
            );
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { loading, event } = this.state;

        const gendersOptions = genders.map((gender) => (
            <Select.Option key={gender} value={gender}>
                <FormattedMessage id={gender} />
            </Select.Option>
        ));

        return (
            <div className={styles.form}>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.firstName" />}
                                name="firstName"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.lastName" />}
                                name="lastName"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.birth" />}
                                name="birthDate"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <DatePicker />
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.gender" />}
                                name="gender"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select>{gendersOptions}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.phone" />}
                                name="phone"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={16}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.positions" />}
                                className={styles.positions}
                            >
                                <Button shape="round">
                                    <span>C</span>
                                    <FormattedMessage id="player.edit.sports.positions.catcher" />
                                </Button>
                                <Button shape="round">
                                    <span>CF</span>
                                    <FormattedMessage id="player.edit.sports.positions.centerFielder" />
                                </Button>
                                <Button shape="round">
                                    <span>DH</span>
                                    <FormattedMessage id="player.edit.sports.positions.designatedHitter" />
                                </Button>
                                <Button shape="round">
                                    <span>1B</span>
                                    <FormattedMessage id="player.edit.sports.positions.firstBase" />
                                </Button>
                                <Button shape="round">
                                    <span>LF</span>
                                    <FormattedMessage id="player.edit.sports.positions.leftFielder" />
                                </Button>
                                <Button shape="round">
                                    <span>P</span>
                                    <FormattedMessage id="player.edit.sports.positions.pitcher" />
                                </Button>
                                <Button shape="round">
                                    <span>RF</span>
                                    <FormattedMessage id="player.edit.sports.positions.rightFielder" />
                                </Button>
                                <Button shape="round">
                                    <span>2B</span>
                                    <FormattedMessage id="player.edit.sports.positions.shortStop" />
                                </Button>
                                <Button shape="round">
                                    <span>SS</span>
                                    <FormattedMessage id="player.edit.sports.positions.thirdBase" />
                                </Button>
                                <Button shape="round">
                                    <span>3B</span>
                                    <FormattedMessage id="player.edit.sports.positions.catcher" />
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col xs={8} sm={6} md={6} lg={4}>
                            <Form.Item label={<FormattedMessage id="player.edit.account.heightFt" />} name="heightFt">
                                <InputNumber type="number" min={1} max={10} defaultValue={1} />
                            </Form.Item>
                        </Col>
                        <Col xs={8} sm={6} md={6} lg={4}>
                            <Form.Item
                                label={<FormattedMessage id="player.edit.account.heightInches" />}
                                name="heightInches"
                            >
                                <InputNumber type="number" min={1} max={80} defaultValue={30} />
                            </Form.Item>
                        </Col>
                        <Col xs={8} sm={6} md={6} lg={4}>
                            <Form.Item name="public">
                                <Checkbox>
                                    <FormattedMessage id="player.edit.account.public" />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={8} sm={6} md={6} lg={4}>
                            <Form.Item label={<FormattedMessage id="player.edit.account.weightLbs" />} name="weightLbs">
                                <InputNumber type="number" min={1} max={500} defaultValue={100} />
                            </Form.Item>
                        </Col>
                        <Col xs={16} sm={6} md={6} lg={4}>
                            <Form.Item name="public2">
                                <Checkbox>
                                    <FormattedMessage id="player.edit.account.public" />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12} lg={8}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.bats" />}
                                name="bats"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select placeholder={<FormattedMessage id="events.event.child.choose" />}></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12} lg={8}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.throws" />}
                                name="throws"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select placeholder={<FormattedMessage id="events.event.child.choose" />}></Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.college" />}
                                name="college"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.grade" />}
                                name="grade"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={50} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.graduationYear" />}
                                name="graduationYear"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={50} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.child.shirtSize" />}
                                name="shirtSize"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={50} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Form.Item className={styles.buttons}>
                            <Link to={`/events/${event?.id}/waivers`}>
                                <Button type="primary" htmlType="submit" size="large" loading={loading}>
                                    <FormattedMessage id="events.event.child.next" tagName="span" />
                                </Button>
                            </Link>
                            <Button type="primary" htmlType="submit" size="large" loading={loading}>
                                <FormattedMessage id="events.event.child.addChild" tagName="span" />
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </div>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { event } = this.state;
        return (
            <div className={styles.header}>
                <Menu.ItemGroup>
                    <Menu.Item key="event-dashboard" icon={<Icon component={backSvg} />}>
                        <Link to={`/events/${event?.id}/register`}>
                            <FormattedMessage id="events.event.back" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className={styles.divider} />

                <Menu.ItemGroup
                    key="event-register-menu"
                    title={!collapsed && <FormattedMessage id="events.event.registration" />}
                >
                    <Menu.Item key="event-register" icon={<Icon component={step1Svg} />}>
                        <Link to={`/events/${event?.id}/register`}>
                            <FormattedMessage id="events.event.parentProfile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-child" icon={<Icon component={step2Svg} />}>
                        <Link to={`/events/${event?.id}/child-profile`}>
                            <FormattedMessage id="events.event.childProfile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-payments" icon={<Icon component={step3Svg} />}>
                        <Link to={`/events/${event?.id}/waivers`}>
                            <FormattedMessage id="events.event.payments" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-confirmation" icon={<Icon component={step4Svg} />}>
                        <Link to={`/events/${event?.id}/confirmation`}>
                            <FormattedMessage id="events.event.confirmation" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </div>
        );
    };

    render() {
        return (
            <LayoutComponent
                page="event-child"
                menu={this.renderMenu}
                content={this.renderContent}
                header={this.renderHeader}
            />
        );
    }
}
export default injectIntl(ChildProfilePage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    event?: Event;
    loading?: boolean;
}
