import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, FormInstance, List, PageHeader, Row, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import collegeApi from '../../apis/CollegeApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Page } from '../../model/Elements';
import { College } from '../../model/Entities';
import notificationService from '../../services/NotificationService';
import tableService from '../../services/TableService';

const { Panel } = Collapse;

class CollegeDirectoryPublicPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const collegesPage = await collegeApi.list(tableService.pageSize, 1);
            this.setState({ collegesPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const collegesPage = await collegeApi.list(pageSize, page);
            this.setState({ collegesPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return (
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <PageHeader title={<Search size="large" />} />
                        </Col>
                        <Col xs={24} md={8}>
                            <Button>
                                <FormattedMessage id="player.collegeDirectory.clear" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            );
        } else {
            return (
                <>
                    <Button type="ghost" icon={<SearchOutlined />} />
                </>
            );
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return this.renderDesktop();
        } else {
            return this.renderMobile();
        }
    };

    renderDesktop = (): React.ReactElement | undefined => {
        const { collegesPage, loading } = this.state;
        const items = collegesPage ? collegesPage.items : [];
        const columns: ColumnsType<College> = [
            {
                title: <FormattedMessage id="player.collegeDirectory.school" />,
                dataIndex: 'school',
                key: 'school',
                sorter: true,
            },
            {
                title: <FormattedMessage id="player.collegeDirectory.division" />,
                dataIndex: 'division',
                key: 'division',
                sorter: true,
            },
            {
                title: <FormattedMessage id="player.collegeDirectory.athleticWebsite" />,
                dataIndex: 'athleticWebsite',
                key: 'athleticWebsite',
                sorter: true,
            },
            {
                title: <FormattedMessage id="player.collegeDirectory.staffDirectory" />,
                dataIndex: 'staffDirectory',
                key: 'staffDirectory',
                sorter: true,
            },
            {
                title: <FormattedMessage id="player.collegeDirectory.admissionLink" />,
                dataIndex: 'admissionLink',
                key: 'admissionLink',
                sorter: true,
            },
        ];

        return (
            <Form ref={this.formRef} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <div>
                            <h1>
                                <FormattedMessage id="player.collegeDirectory.title" />
                            </h1>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <div>
                            <FormattedMessage id="player.collegeDirectory.description" />
                        </div>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <div>
                            <h1>
                                <FormattedMessage id="player.collegeDirectory.colleges" />
                            </h1>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={24}>
                        <Table
                            dataSource={items}
                            columns={columns}
                            pagination={tableService.createPagination(collegesPage)}
                            rowKey="id"
                            onChange={this.list}
                            showSorterTooltip={false}
                            loading={loading}
                        />
                    </Col>
                </Row>
            </Form>
        );
    };

    renderMobile = (): React.ReactElement | undefined => {
        const { collegesPage, loading } = this.state;
        const items = collegesPage ? collegesPage.items : [];

        return (
            <Form ref={this.formRef} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <div>
                            <h1>
                                <FormattedMessage id="player.collegeDirectory.title" />
                            </h1>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <div>
                            <FormattedMessage id="player.collegeDirectory.description" />
                        </div>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <div>
                            <h1>
                                <FormattedMessage id="player.collegeDirectory.colleges" />
                            </h1>
                        </div>
                    </Col>
                </Row>

                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 3,
                        md: 3,
                        lg: 4,
                        xl: 5,
                        xxl: 6,
                    }}
                    dataSource={items}
                    renderItem={(college) => (
                        <List.Item>
                            <Collapse defaultActiveKey={['0']}>
                                <Panel header={college.school} key="1">
                                    <p>{college.division}</p>
                                    <p>{college.athleticWebsite}</p>
                                    <p>{college.staffDirectory}</p>
                                    <p>{college.admissionLink}</p>
                                </Panel>
                            </Collapse>
                        </List.Item>
                    )}
                    loading={loading}
                />
            </Form>
        );
    };

    render() {
        return <LayoutComponent page="colleges-public" content={this.renderContent} header={this.renderHeader} />;
    }
}
export default injectIntl(CollegeDirectoryPublicPage);

interface Props extends WrappedComponentProps {}

interface State {
    collegesPage?: Page<College>;
    loading?: boolean;
    searchText?: string;
}
