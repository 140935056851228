import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import divisionApi from '../../../apis/DivisionApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Division } from '../../../model/Entities';
import notificationService from '../../../services/NotificationService';
import styles from './DivisionsPage.module.scss';

class DivisionsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { divisions: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const divisions = await divisionApi.list();

            this.setState({ divisions });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                <div className={styles.table}>{this.renderTable()}</div>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        return (
            <div className={styles.toolbar}>
                <h1>
                    <FormattedMessage id="division.title" />
                </h1>
                <Link to="/admin/divisions/new">
                    <Tooltip title={<FormattedMessage id="divisions.tooltip" />}>
                        <Button type="primary" size="large" className={styles.add} icon={<PlusOutlined />}>
                            <FormattedMessage id="divisions.add" />
                        </Button>
                    </Tooltip>
                </Link>
            </div>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { loading, divisions } = this.state;
        const columns: ColumnsType<Division> = [
            {
                title: <FormattedMessage id="divisions.name" />,
                dataIndex: 'name',
                key: 'name',
                render: (value: string, division: Division) => (
                    <Link to={`/admin/divisions/${division.id}`}>{value}</Link>
                ),
            },
        ];

        return <Table dataSource={divisions} columns={columns} rowKey="id" loading={loading} />;
    };

    render() {
        return <LayoutComponent page="admin-divisions" content={this.renderContent} />;
    }
}
export default injectIntl(DivisionsPage);

interface Props extends WrappedComponentProps {}

interface State {
    divisions: Division[];
    loading?: boolean;
}
