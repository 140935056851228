import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import authService from '../services/AuthService';

let baseURL = process.env.REACT_APP_API_URL;
export const apiBaseURL = baseURL;

/****** RESOURCES APIS ***/

// request interceptor
const axiosRequestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const accessToken = await authService.getAccessToken();
    return Promise.resolve({
        ...config,
        headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
            ...config.headers,
        },
    });
};

/*** COMMON API ***/

// config
const apiAxiosConfig: AxiosRequestConfig = {
    baseURL: apiBaseURL,
};

// create axios http client
const apiAxios: AxiosInstance = axios.create(apiAxiosConfig);
apiAxios.interceptors.request.use(axiosRequestInterceptor);
export default apiAxios;
