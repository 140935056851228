import { Page } from '../model/Elements';
import { Organization } from '../model/Entities';
import apiAxios from './CustomAxios';

class OrganizationApi {
    list = async (limit: number, page: number, sortField?: string, sortOrder?: string): Promise<Page<Organization>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getSortField(sortField);
        const response = await apiAxios.get<Page<Organization>>('/v1/colleges', {
            params: { limit, page, [field]: order },
        });

        return response.data;
    };

    listAll = async (): Promise<Organization[]> => {
        let page = 1;
        let total: number;
        let items: Organization[] = [];
        do {
            const itemsPage = await this.list(500, page);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        return items;
    };

    get = async (organizationId?: string): Promise<Organization> => {
        const response = await apiAxios.get<Organization>(`/v1/colleges/${organizationId}`);
        return response.data;
    };

    create = async (organization: Organization): Promise<Organization> => {
        const response = await apiAxios.post<Organization>('/v1/colleges', organization);
        return response.data;
    };

    update = async (organization: Organization): Promise<Organization> => {
        const response = await apiAxios.put<Organization>(`/v1/colleges/${organization.id}`, organization);
        return response.data;
    };

    delete = async (organization: Organization): Promise<void> => {
        await apiAxios.delete(`/v1/colleges/${organization.id}`);
    };

    private getSortField(sortField?: string): string {
        let field: string = 'sorting.Name';
        return field;
    }
}
const organizationApi: OrganizationApi = new OrganizationApi();
export default organizationApi;
