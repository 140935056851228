import { LegacyPage } from '../model/Elements';
import { Media } from '../model/Entities';

class MediaApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<LegacyPage<Media>> => {
        return Promise.resolve({
            content: media,
            totalElements: 2,
            totalPages: 1,
            size: 2,
            number: 1,
            numberOfElements: 2,
            first: true,
            last: true,
            empty: false,
            pageable: {
                pageNumber: page,
                pageSize: size,
            },
            sort: {
                unsorted: false,
                sorted: true,
                empty: false,
                field: sortField,
                order: sortOrder,
            },
        });
    };
}

const mediaApi: MediaApi = new MediaApi();
export default mediaApi;

// mock data
const media: Media[] = [...Array(10)].map((v, i) => ({
    id: i,
    name: `Video ${i}`,
    type: 'photo',
}));
