import { Button, Col, Form, FormInstance, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../context/CustomContext';
import notificationService from '../../../services/NotificationService';

class ShareComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    update = async () => {
        try {
            this.setState({ loading: true });
            //const values = await this.formRef.current?.validateFields();
            //await this.props.onUpdate(values);
            this.formRef.current?.resetFields();
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.cancel}
                footer={[
                    <Button type="primary">
                        <FormattedMessage id="player.profile.copyLink" />
                    </Button>,
                    <Button type="primary">
                        <FormattedMessage id="player.profile.shareFacebook" />
                    </Button>,
                ]}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row align="middle" gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Form.Item name="share">
                                <div>
                                    <h1>{<FormattedMessage id="player.profile.share" />}</h1>
                                </div>
                            </Form.Item>
                            <Form.Item name="shareDescription">
                                <div>
                                    <h4>{<FormattedMessage id="player.profile.shareDescription" />}</h4>
                                </div>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="player.profile.shareLink" />} name="shareLink">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(ShareComponent);

interface Props extends WrappedComponentProps {
    visible?: boolean;
    //onUpdate: (values: any) => Promise<void>;
    onCancel: () => void;
}

interface State {
    loading?: boolean;
}
