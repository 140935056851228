import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import CustomContext from '../../context/CustomContext';
import { Player } from '../../model/Entities';
import tableService from '../../services/TableService';
import playerApi from '../../apis/PlayerApi';
import notificationService from '../../services/NotificationService';
import { LegacyPage } from '../../model/Elements';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';

class CombinePage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const leadersPage = await playerApi.list(0, tableService.pageSize, 'lastName', true);
            this.setState({ leadersPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const leadersPage = await playerApi.list(page - 1, pageSize, sortField, sortOrder, searchText);
            this.setState({ leadersPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    search = async (searchText: string) => {
        const { leadersPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = leadersPage!.size;
            const sortField = leadersPage!.sort.field!;
            const sortOrder = leadersPage!.sort.order!;
            const leadersPageNew = await playerApi.list(0, pageSize, sortField, sortOrder, searchText);
            this.setState({ leadersPage: leadersPageNew, searchText, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return <>{this.renderTable()}</>;
    };

    renderTable = (): React.ReactElement | undefined => {
        const { leadersPage, loading } = this.state;
        const items = leadersPage ? leadersPage.content : [];
        const columns: ColumnsType<Player> = [
            {
                title: <FormattedMessage id="player.firstName" />,
                dataIndex: 'firstName',
                key: 'firstName',
                sorter: true,
                render: (value: string, player: Player) => <Link to={`/players/${player.id}/profile`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="player.lastName" />,
                dataIndex: 'lastName',
                key: 'lastName',
                sorter: true,
                defaultSortOrder: 'ascend',
                render: (value: string, player: Player) => <Link to={`/players/${player.id}/profile`}>{value}</Link>,
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createLegacyPagination(leadersPage)}
                rowKey="id"
                onChange={this.list}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent page="combine" content={this.renderContent} />;
    }
}
export default injectIntl(CombinePage);

interface Props extends WrappedComponentProps {}

interface State {
    leadersPage?: LegacyPage<Player>;
    loading?: boolean;
    searchText?: string;
}
