import { LegacyPage } from '../model/Elements';
import { Team } from '../model/Entities';
import organizationApi from './OrganizationApi';

class TeamApi {
    list = async (
        page: number,
        size: number,
        sortField?: string,
        sortOrder?: boolean,
        searchText?: string,
    ): Promise<LegacyPage<Team>> => {
        const teams = await this.listAll();
        return Promise.resolve({
            content: teams,
            totalElements: 2,
            totalPages: 1,
            size: 2,
            number: 1,
            numberOfElements: 2,
            first: true,
            last: true,
            empty: false,
            pageable: {
                pageNumber: page,
                pageSize: size,
            },
            sort: {
                unsorted: false,
                sorted: true,
                empty: false,
                field: sortField,
                order: sortOrder,
            },
        });
    };

    listAll = async (): Promise<Team[]> => {
        const organizations = await organizationApi.listAll();
        const teams: Team[] = [];
        organizations
            .filter((organization) => organization.teams)
            .flatMap((organization) => Array.prototype.push.apply(teams, organization.teams!));

        const distinctTeams: Team[] = teams.filter((team, i, arr) => arr.findIndex((t) => t.name === team.name) === i);

        return distinctTeams;
    };

    get = async (teamId?: string): Promise<Team> => {
        const organizations = await organizationApi.listAll();
        const teams: Team[] = [];
        organizations
            .filter((organization) => organization.teams)
            .flatMap((organization) => Array.prototype.push.apply(teams, organization.teams!));

        const distinctTeams: Team[] = teams.filter((team, i, arr) => arr.findIndex((t) => t.name === team.name) === i);

        return distinctTeams.find((t) => t.id === teamId) || distinctTeams[0];
    };
}

const teamApi: TeamApi = new TeamApi();
export default teamApi;
