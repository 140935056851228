import { Page } from '../model/Elements';
import { User } from '../model/Entities';
import apiAxios from './CustomAxios';

class UserApi {
    list = async (limit: number, page: number, sortField?: string[], sortOrder?: string): Promise<Page<User>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getSortField(sortField);
        const response = await apiAxios.get<Page<User>>('/v1/users', {
            params: { limit, page, [field]: order },
        });

        return response.data;
    };

    listAll = async (): Promise<User[]> => {
        return Promise.resolve(users);
    };

    get = async (userId: string): Promise<User> => {
        const response = await apiAxios.get<User>(`/v1/users/${userId}`);
        return response.data;
    };

    getFromCognito = async (): Promise<User> => {
        const response = await apiAxios.get<User>(`/v1/users/by-cognito`);
        return response.data;
    };

    create = async (user: User): Promise<User> => {
        const profile = user.profile;
        const response = await apiAxios.post<User>('/v1/users', profile);
        return response.data;
    };

    update = async (user: User): Promise<User> => {
        const profile = user.profile;
        const response = await apiAxios.put<User>(`/v1/users/${user.id}`, profile);
        return response.data;
    };

    delete = async (user: User): Promise<void> => {
        await apiAxios.delete(`/v1/users/${user.id}`);
    };

    private getSortField(sortField?: string[]): string {
        let field: string = 'sorting.GivenName';
        if (!sortField) {
            return field;
        }
        if (sortField[1] === 'givenName') {
            field = 'sorting.GivenName';
        } else if (sortField[1] === 'familyName') {
            field = 'sorting.FamilyName';
        }
        return field;
    }
}

const userApi: UserApi = new UserApi();
export default userApi;

// mock data
const users: User[] = [...Array(4)].map((v, i) => ({
    id: i.toString(),
    firstName: `User ${i}`,
    lastName: 'Williams',
    email: `user${i}williams@top100.com`,
    phone: '999 999 999',
    birthDate: '1990-09-09',
    gender: 'male',
}));
