import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import Icon, { ArrowLeftOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Divider, Menu, PageHeader, Select } from 'antd';
import { ReactComponent as dashboardSvg } from '../../../resources/images/ico-dashboard.svg';
import { ReactComponent as sportSvg } from '../../../resources/images/ico-rosterSport.svg';
import { ReactComponent as teamsSvg } from '../../../resources/images/ico-rosterTeams.svg';
import { ReactComponent as positionsSvg } from '../../../resources/images/ico-rosterPositions.svg';
import { ReactComponent as gradesSvg } from '../../../resources/images/ico-rosterGrades.svg';
import { ReactComponent as gendersSvg } from '../../../resources/images/ico-rosterGenders.svg';
import { ReactComponent as gridSvg } from '../../../resources/images/ico-grid.svg';
import { ReactComponent as listSvg } from '../../../resources/images/ico-list.svg';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import organizationApi from '../../../apis/OrganizationApi';
import sportApi from '../../../apis/SportApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Organization, Position, Sport, Team } from '../../../model/Entities';
import { DisplayType, genders, grades } from '../../../model/Types';
import notificationService from '../../../services/NotificationService';
import stringService from '../../../services/StringService';
import styles from './RosterPage.module.scss';
import TeamComponent from './TeamComponent/TeamComponent';
import positionApi from '../../../apis/PositionApi';

class RosterPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            display: 'cards',
            filteredTeams: [],
            teams: [],
            teamIds: [],
            sports: [],
            positionIds: [],
            positions: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const params = new URLSearchParams(this.props.location.search);
            const responses = await Promise.all([organizationApi.get(this.props.match.params.id), sportApi.listAll()]);
            const organization = responses[0];
            const sports = responses[1];
            const sportId = params.get('sportId') ? +params.get('sportId')! : sports[0].id!;
            this.filterBySport(organization, sportId);

            this.setState({ organization, sports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    filter = (teams: Team[], teamIds: string[], searchText?: string) => {
        const filteredTeams = teams
            .filter((t) => teamIds.length === 0 || teamIds.includes(t.id!))
            .filter((t) => stringService.search(t.name, searchText))
            .sort((a, b) => stringService.sort(a.name, b.name));

        this.setState({ filteredTeams });
    };

    filterBySport = async (organization: Organization, sportId: number) => {
        const teams = organization
            .teams!.filter((t) => t.sportId === sportId)
            .sort((a, b) => stringService.sort(a.name, b.name));
        const teamIds: string[] = [];
        this.filter(teams, teamIds, this.state.searchText);
        this.setState({ sportId, teams, teamIds });

        const positions = await positionApi.list(sportId);
        const positionIds: number[] = positions.flatMap((p) => p.id);
        this.setState({ positions, positionIds });
    };

    filterByTeamId = (teamId: string) => {
        const teamIds = this.state.teamIds.includes(teamId)
            ? this.state.teamIds.filter((id) => id !== teamId)
            : [...this.state.teamIds, teamId];

        this.filter(this.state.teams, teamIds, this.state.searchText);
        this.setState({ teamIds });
    };

    filterBySearchText = (searchText?: string) => {
        this.filter(this.state.teams, this.state.teamIds, searchText);
        this.setState({ searchText });
    };

    switchDisplay = (display: DisplayType) => {
        this.setState({ display });
    };

    /*** COMPONENTS ***/
    renderLogo = (): React.ReactElement | undefined => {
        return (
            <Link to="/rosters">
                <Button type="ghost" icon={<ArrowLeftOutlined />} />
            </Link>
        );
    };

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            const { intl } = this.props;
            return (
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <PageHeader
                        className={styles.content}
                        title={
                            <Search
                                size="large"
                                className={styles.search}
                                placeholder={intl.formatMessage({ id: 'button.search' })}
                                onSearch={(value) => this.filterBySearchText(value)}
                                enterButton={''}
                            />
                        }
                        extra={
                            <div className={styles.tools}>
                                <Button
                                    shape="round"
                                    icon={<Icon component={listSvg} />}
                                    onClick={() => this.switchDisplay('list')}
                                ></Button>
                                <Button
                                    shape="round"
                                    icon={<Icon component={gridSvg} />}
                                    onClick={() => this.switchDisplay('cards')}
                                ></Button>
                                <Select
                                    className={styles.sort}
                                    placeholder={<FormattedMessage id="admin.rosters.roster.sort" />}
                                ></Select>
                            </div>
                        }
                    />
                </div>
            );
        } else {
            return <Button type="ghost" icon={<FilterOutlined />} />;
        }
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { sports, organization, sportId, teamIds, teams, positions } = this.state;

        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item key="events-dashboard" icon={<Icon component={dashboardSvg} />}>
                        <Link to="/rosters">
                            <FormattedMessage id="navigation.admin.dashboard" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                <Menu.ItemGroup key="roster" title={!collapsed && <FormattedMessage id="roster.navigation" />}>
                    <Menu.SubMenu
                        key="roster-sports"
                        icon={<Icon component={sportSvg} />}
                        title={<FormattedMessage id="roster.navigation.sports" />}
                    >
                        {sports?.map((sport) => (
                            <Menu.Item
                                key={sport.id}
                                onClick={() => this.filterBySport(organization!, sport.id!)}
                                className={sportId === sport.id ? styles.selected : styles.unselected}
                            >
                                <FormattedMessage id={sport.name} />
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="roster-teams"
                        icon={<Icon component={teamsSvg} />}
                        title={<FormattedMessage id="roster.navigation.teams" />}
                    >
                        {teams.map((team) => (
                            <Menu.Item
                                key={team.id}
                                onClick={() => this.filterByTeamId(team.id!)}
                                className={teamIds.includes(team.id!) ? styles.selected : styles.unselected}
                            >
                                {team.name}
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="roster-positions"
                        icon={<Icon component={positionsSvg} />}
                        title={<FormattedMessage id="roster.navigation.positions" />}
                    >
                        {positions.map((position) => (
                            <Menu.Item key={position.id}>{position.name}</Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="roster-grades"
                        icon={<Icon component={gradesSvg} />}
                        title={<FormattedMessage id="roster.navigation.grades" />}
                    >
                        {grades.map((grade) => (
                            <Menu.Item key={grade}>
                                <FormattedMessage id={grade} />
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="roster-genders"
                        icon={<Icon component={gendersSvg} />}
                        title={<FormattedMessage id="roster.navigation.genders" />}
                    >
                        {genders.map((gender) => (
                            <Menu.Item key={gender}>
                                <FormattedMessage id={gender} />
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                </Menu.ItemGroup>
            </>
        );
    };

    renderList = (): React.ReactElement | undefined => {
        const { organization, display, filteredTeams, positionIds } = this.state;

        return (
            <>
                <h2 className={styles.orgName}>
                    <div className={styles.logo}>
                        {organization && <img src={organization?.schoolLogo} alt={organization?.name} />}
                    </div>
                    {organization?.name}
                </h2>
                {filteredTeams.map((team: Team) => (
                    <TeamComponent id={team.id} team={team} key={team.id} positionIds={positionIds} display={display} />
                ))}
            </>
        );
    };

    render() {
        return (
            <LayoutComponent
                content={this.renderList}
                menu={this.renderMenu}
                header={this.renderHeader}
                logo={this.renderLogo}
                page="roster"
            />
        );
    }
}
export default injectIntl(RosterPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    display: DisplayType;
    organization?: Organization;
    sports: Sport[];
    sportId?: number;
    teams: Team[];
    teamIds: string[];
    positionIds: number[];
    positions: Position[];
    filteredTeams: Team[];
    loading?: boolean;
    searchText?: string;
}
