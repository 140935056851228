import Icon, { FilterOutlined } from '@ant-design/icons';
import { Button, Card, List, PageHeader, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import organizationApi from '../../apis/OrganizationApi';
import sportApi from '../../apis/SportApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Organization, Roster, Sport } from '../../model/Entities';
import { ReactComponent as baseballSvg } from '../../resources/images/sportBaseball.svg';
import { ReactComponent as softballSvg } from '../../resources/images/sportSoftball.svg';
import notificationService from '../../services/NotificationService';
import stringService from '../../services/StringService';
import styles from './RostersPage.module.scss';

class RostersPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            rosters: [],
            filteredRosters: [],
            organizations: [],
            sports: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const responses = await Promise.all([organizationApi.listAll(), sportApi.listAll()]);
            const organizations = responses[0].sort((a, b) => stringService.sort(a.name, b.name));
            const sports = responses[1].sort((a, b) => stringService.sort(a.name, b.name));
            const rosters = this.createRosters(organizations, sports);
            const filteredRosters = [...rosters];

            this.setState({ rosters, filteredRosters, sports, organizations });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    filter = async (sportId?: number, organizationId?: string, searchText?: string) => {
        try {
            this.setState({ loading: true });
            const filteredRosters = this.state.rosters
                .filter((r) => !sportId || r.sport.id === sportId)
                .filter((r) => !organizationId || r.organization.id === organizationId)
                .filter((r) => stringService.search(r.organization.name, searchText));
            this.setState({ sportId, organizationId, filteredRosters });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /**
     * Creates rosters from organizations and their teams.
     * @param organizations - the organizations
     * @param sports - the sports
     * @returns the rosters
     */
    private createRosters = (organizations: Organization[], sports: Sport[]): Roster[] => {
        return organizations
            .filter((organization) => organization.teams)
            .flatMap((organization) =>
                Array.from(new Set(organization.teams!.map((team) => team.sportId!)))
                    .map((sportId) => sports.find((sport) => sport.id === sportId))
                    .filter((sport) => sport)
                    .map((sport) => ({ organization, sport } as Roster)),
            )
            .sort(
                (a, b) =>
                    stringService.sort(a.organization.name, b.organization.name) ||
                    stringService.sort(a.sport.name, b.sport.name),
            );
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { sportId, organizationId } = this.state;

        if (desktop) {
            const { intl } = this.props;
            return (
                <PageHeader
                    title={
                        <Search
                            placeholder={intl.formatMessage({ id: 'button.search' })}
                            className={styles.search}
                            onSearch={(value) => this.filter(sportId, organizationId, value)}
                            enterButton={''}
                        />
                    }
                />
            );
        } else {
            return <Button type="ghost" icon={<FilterOutlined />} />;
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        return (
            <>
                {desktop && this.renderToolbar()}
                {this.renderList(desktop)}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { sports, organizations, sportId, organizationId, searchText } = this.state;
        let sportOptions = sports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));
        sportOptions = [
            <Select.Option key="-" value="">
                <FormattedMessage id="rosters.toolbar.sports" />
            </Select.Option>,
            ...sportOptions,
        ];
        let organizationOptions = organizations.map((organization) => (
            <Select.Option key={organization.id} value={organization.id!}>
                {organization.name}
            </Select.Option>
        ));
        organizationOptions = [
            <Select.Option key="-" value="">
                <FormattedMessage id="rosters.toolbar.organizations" />
            </Select.Option>,
            ...organizationOptions,
        ];

        return (
            <PageHeader
                title={<FormattedMessage id="rosters.title" />}
                extra={[
                    <Select
                        key="sports"
                        size="large"
                        className={styles.sports}
                        placeholder={<FormattedMessage id="rosters.toolbar.sports" />}
                        onChange={(value: number) => this.filter(value, organizationId, searchText)}
                    >
                        {sportOptions}
                    </Select>,
                    <Select
                        key="organizations"
                        size="large"
                        className={styles.organizations}
                        placeholder={<FormattedMessage id="rosters.toolbar.organizations" />}
                        onChange={(value: string) => this.filter(sportId, value, searchText)}
                    >
                        {organizationOptions}
                    </Select>,
                ]}
                className={styles.toolbar}
            ></PageHeader>
        );
    };

    renderSportIcon = (sport: Sport): React.ReactElement | undefined => {
        switch (sport.code) {
            case 'SOFTBALL':
                return <Icon component={softballSvg} />;
            case 'BASEBALL':
                return <Icon component={baseballSvg} />;
            default:
                return <Icon component={baseballSvg} />;
        }
    };

    renderList = (desktop: boolean): React.ReactElement | undefined => {
        const { filteredRosters, loading } = this.state;

        return (
            <List
                grid={{
                    gutter: 30,
                    xs: 2,
                    sm: 3,
                    md: 3,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                }}
                dataSource={filteredRosters}
                renderItem={(roster) => (
                    <List.Item>
                        <Link
                            to={`/rosters/${roster.organization.id}?sportId=${roster.sport.id}`}
                            className={styles.card}
                        >
                            <Card
                                className={styles.card}
                                cover={
                                    <img
                                        src={roster.organization.schoolLogo}
                                        alt={roster.organization.name}
                                        className={styles.image}
                                    />
                                }
                            >
                                <Card.Meta
                                    title={roster.organization.name}
                                    className={styles.meta}
                                    description={
                                        <div className={styles.description}>
                                            {this.renderSportIcon(roster.sport)}
                                            {roster.sport.name}
                                        </div>
                                    }
                                />
                                <div className={styles.footer}>
                                    <div className={styles.ico}></div>#1 - {roster.leader}
                                </div>
                            </Card>
                        </Link>
                    </List.Item>
                )}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent page="rosters" content={this.renderContent} header={this.renderHeader} />;
    }
}
export default injectIntl(RostersPage);

interface Props extends WrappedComponentProps {}

interface State {
    rosters: Roster[];
    filteredRosters: Roster[];
    organizations: Organization[];
    sports: Sport[];
    searchText?: string;
    organizationId?: string;
    sportId?: number;
    loading?: boolean;
}
