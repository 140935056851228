import React, { Component } from 'react';
import './App.less';
import './normalize.css';
import { ConfigProvider, message } from 'antd';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import messagesEn from './i18n/en.json';
import enUS from 'antd/lib/locale/en_US';
import CustomContext from './context/CustomContext';
import { Context } from './model/Elements';
import { LegacyAuth } from './model/Entities';
import authService from './services/AuthService';
import { RoutesComponent } from './components/RoutesComponent/RoutesComponent';
import Amplify from '@aws-amplify/core';
import awsConfig from './config/AwsConfig';
Amplify.configure(awsConfig);

class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            context: {
                updateAuth: this.updateContextAuth,
            },
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            // set-up antd configuration
            message.config({
                maxCount: 1,
            });

            // set-up locale
            moment.locale('en');

            // set-up current auth
            const auth = await authService.get();
            this.updateContextAuth(auth);

            const initialized = true;
            this.setState({ initialized });
        } catch (error) {
            message.error('Internal error');
        }
    };

    private updateContextAuth = (auth?: LegacyAuth): void => {
        const context: Context = Object.assign({}, this.state.context, { auth });
        this.setState({ context });
    };

    /*** COMPONENTS ***/

    render() {
        if (this.state.initialized) {
            return (
                <ConfigProvider locale={enUS}>
                    <CustomContext.Provider value={this.state.context}>
                        <IntlProvider locale="en-US" defaultLocale="en-US" messages={messagesEn}>
                            <RoutesComponent />
                        </IntlProvider>
                    </CustomContext.Provider>
                </ConfigProvider>
            );
        } else {
            return <></>;
        }
    }
}

export default App;

interface Props {}

interface State {
    initialized?: boolean;
    context: Context;
}
