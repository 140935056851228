import Icon from '@ant-design/icons';
import { Button, Col, DatePicker, Form, FormInstance, Input, message, Row, Select, Space } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import userApi from '../../apis/UserApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { User } from '../../model/Entities';
import { genders } from '../../model/Types';
import { ReactComponent as SaveSvg } from '../../resources/images/save.svg';
import notificationService from '../../services/NotificationService';

class SettingsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const userId = this.context.auth!.id;
            const user = await userApi.get(userId);

            this.setState({ user, loading: false });
            this.formRef.current!.setFieldsValue(user);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    save = async (values: any) => {
        try {
            this.setState({ loading: true });
            let user: User = Object.assign({}, this.state.user, values);
            user = user.id ? await userApi.update(user) : await userApi.create(user);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ user });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'user.status.duplicate' },
            ]);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return this.renderForm();
    };

    renderForm = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        const gendersOptions = genders.map((gender) => (
            <Select.Option key={gender} value={gender}>
                <FormattedMessage id={gender} />
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="settings.firstName" />}
                            name={['profile', 'givenName']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="settings.lastName" />}
                            name={['profile', 'familyName']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="settings.email" />}
                            name={['profile', 'email']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="settings.phone" />}
                            name={['profile', 'phone']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="settings.birth" />}
                            name={['profile', 'birthday']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Space direction="vertical">
                                <DatePicker style={{ width: 395 }} />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="settings.gender" />}
                            name={['profile', 'gender']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select style={{ width: 395 }}>{gendersOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="settings.position" />} name="position">
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="settings.hobbies" />} name="hobbies">
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="settings.coachingYears" />} name="coachingYears">
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="settings.playingYears" />} name="platingYears">
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="settings.college" />} name="college">
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="settings.highSchool" />} name="highSchool">
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="settings.spouseName" />} name="spouseName">
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="settings.kids" />} name="kids">
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item className="buttons">
                    <Space size={15}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            icon={<Icon component={SaveSvg} />}
                            loading={loading}
                        >
                            <FormattedMessage id="settings.button.save" tagName="span" />
                        </Button>
                        <Link to="/rosters">
                            <Button type="text" size="large">
                                <FormattedMessage id="settings.button.back" tagName="span" />
                            </Button>
                        </Link>
                    </Space>
                </Form.Item>
            </Form>
        );
    };

    render() {
        return <LayoutComponent page="settings" content={this.renderContent} />;
    }
}
export default injectIntl(SettingsPage);

interface Props extends WrappedComponentProps {}

interface State {
    user?: User;
    loading?: boolean;
}
