import * as React from 'react';
import { Switch, Route } from 'react-router';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import EventsPage from '../../pages/EventsPage/EventsPage';
import LeaderboardPage from '../../pages/LeaderboardPage/LeaderboardPage';
import PlayerProfilePage from '../../pages/PlayerProfilePage/PlayerProfilePage';
import PlayerDataPage from '../../pages/PlayerDataPage/PlayerDataPage';
import EditProfilePage from '../../pages/EditProfilePage/EditProfilePage';
import MediaPage from '../../pages/MediaPage/MediaPage';
import CombinePage from '../../pages/CombinePage/CombinePage';
import CollegeDirectoryPage from '../../pages/CollegeDirectoryPage/CollegeDirectoryPage';
import PlayerCodePage from '../../pages/PlayerCodePage/PlayerCodePage';
import TutorialsPage from '../../pages/TutorialsPage/TutorialsPage';
import CodesPage from '../../pages/CodesPage/CodesPage';
import AddTeamPage from '../../pages/AddTeamPage/AddTeamPage';
import SettingsPage from '../../pages/SettingsPage/SettingsPage';
import RostersPage from '../../pages/RostersPage/RostersPage';
import SignInPage from '../../pages/SignInPage/SignInPage';
import RosterPage from '../../pages/RostersPage/RosterPage/RosterPage';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import DefaultRoute from '../DefaultRoute/DefaultRoute';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage/ForgotPasswordPage';
import EventPage from '../../pages/EventsPage/EventPage/EventPage';
import EventRegistrationPage from '../../pages/EventsPage/EventPage/EventRegistrationPage/EventRegistrationPage';
import ChildProfilePage from '../../pages/EventsPage/EventPage/ChildProfilePage/ChildProfilePage';
import WaiversPage from '../../pages/EventsPage/EventPage/WaiversPage/WaiversPage';
import ConfirmationPage from '../../pages/EventsPage/EventPage/ConfirmationPage/ConfirmationPage';
import CollegeDirectoryPublicPage from '../../pages/CollegeDirectoryPublicPage/CollegeDirectoryPublicPage';
import EventCreationPage from '../../pages/EventsPage/EventCreationPage/EventCreationPage';
import EventsManagementPage from '../../pages/EventsPage/EventsManagementPage/EventsManagementPage';
import EventsMediaPage from '../../pages/EventsPage/EventsMediaPage/EventsMediaPage';
import OrganizationsPage from '../../pages/Backend/OrganizationPage/OrganizationsPage';
import OrganizationPage from '../../pages/Backend/OrganizationPage/OrganizationPage/OrganizationPage';
import TeamsPage from '../../pages/Backend/TeamsPage/TeamsPage';
import TeamPage from '../../pages/Backend/TeamsPage/TeamPage/TeamPage';
import PlayerPage from '../../pages/Backend/PlayersPage/PlayerPage/PlayerPage';
import PlayersPage from '../../pages/Backend/PlayersPage/PlayersPage';
import CollegesPage from '../../pages/Backend/CollegesPage/CollegesPage';
import CollegePage from '../../pages/Backend/CollegesPage/CollegePage/CollegePage';
import UsersPage from '../../pages/Backend/UsersPage/UsersPage';
import UserPage from '../../pages/Backend/UsersPage/UserPage/UserPage';
import CoachPage from '../../pages/Backend/CoachesPage/CoachPage/CoachPage';
import CoachesPage from '../../pages/Backend/CoachesPage/CoachesPage';
import DivisionPage from '../../pages/Backend/DivisionsPage/DivisionPage/DivisionPage';
import DivisionsPage from '../../pages/Backend/DivisionsPage/DivisionsPage';
import AwardPage from '../../pages/Backend/AwardsPage/AwardPage/AwardPage';
import AwardsPage from '../../pages/Backend/AwardsPage/AwardsPage';

export const RoutesComponent: React.FC = () => {
    return (
        <Switch>
            {/*  All  */}
            <DefaultRoute exact path="/" />
            <Route exact path="/leaderboard" component={LeaderboardPage} />
            <Route exact path="/events" component={EventsPage} />
            <Route exact path="/events/:id/summary" component={EventPage} />
            <Route exact path="/events/:id/register" component={EventRegistrationPage} />
            <Route exact path="/events/:id/child-profile" component={ChildProfilePage} />
            <Route exact path="/events/:id/waivers" component={WaiversPage} />
            <Route exact path="/events/:id/confirmation" component={ConfirmationPage} />
            <Route exact path="/players/:id/profile" component={PlayerProfilePage} />
            <Route exact path="/players/:id/data" component={PlayerDataPage} />
            <Route exact path="/players/:id/colleges" component={CollegeDirectoryPage} />
            <Route exact path="/colleges" component={CollegeDirectoryPublicPage} />

            {/*  Player  */}
            <ProtectedRoute
                exact
                path="/players/:id/edit"
                component={EditProfilePage}
                authorities={['ROLE_PLAYER', 'ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/players/:id/media"
                component={MediaPage}
                authorities={['ROLE_PLAYER', 'ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/combine"
                component={CombinePage}
                authorities={['ROLE_PLAYER', 'ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/players/:id/code"
                component={PlayerCodePage}
                authorities={['ROLE_PLAYER', 'ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />

            {/*  Organization, Admin  */}
            <ProtectedRoute
                exact
                path="/tutorials"
                component={TutorialsPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/codes"
                component={CodesPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/add-team"
                component={AddTeamPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/rosters"
                component={RostersPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/rosters/:id"
                component={RosterPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />

            {/*Admin */}
            <ProtectedRoute exact path="/event-creation" component={EventCreationPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute
                exact
                path="/events-management"
                component={EventsManagementPage}
                authorities={['ROLE_ADMIN']}
            />
            <ProtectedRoute exact path="/events-media" component={EventsMediaPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute
                exact
                path="/admin/organizations"
                component={OrganizationsPage}
                authorities={['ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/admin/organizations/:id"
                component={OrganizationPage}
                authorities={['ROLE_ADMIN']}
            />
            <ProtectedRoute exact path="/admin/teams" component={TeamsPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/teams/:id" component={TeamPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/players" component={PlayersPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/players/:id" component={PlayerPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/colleges" component={CollegesPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/colleges/:id" component={CollegePage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/users" component={UsersPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/users/:id" component={UserPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/coaches" component={CoachesPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/coaches/:id" component={CoachPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/divisions" component={DivisionsPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/divisions/:id" component={DivisionPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/awards" component={AwardsPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/awards/:id" component={AwardPage} authorities={['ROLE_ADMIN']} />

            <ProtectedRoute exact path="/settings" component={SettingsPage} />
            <Route exact path="/signin" component={SignInPage} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/error" component={ErrorPage} />
            <Route component={ErrorPage} />
        </Switch>
    );
};
