import Icon from '@ant-design/icons';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PageType } from '../../model/Types';
import { ReactComponent as adminCodesSvg } from '../../resources/images/ico-adminCodes.svg';
import { ReactComponent as adminTeamSvg } from '../../resources/images/ico-adminTeam.svg';
import { ReactComponent as adminTutorialSvg } from '../../resources/images/ico-adminTutorial.svg';
import { ReactComponent as collegeSvg } from '../../resources/images/ico-collegeDirectory.svg';
import { ReactComponent as eventsSvg } from '../../resources/images/ico-events.svg';
import { ReactComponent as leaderboardSvg } from '../../resources/images/ico-leaderboard.svg';
import { ReactComponent as playerCodeSvg } from '../../resources/images/ico-playerCode.svg';
import { ReactComponent as playerDataSvg } from '../../resources/images/ico-playerData.svg';
import { ReactComponent as playerEditSvg } from '../../resources/images/ico-playerEdit.svg';
import { ReactComponent as playerPhotosSvg } from '../../resources/images/ico-playerPhotos.svg';
import { ReactComponent as playerProfileSvg } from '../../resources/images/ico-playerProfile.svg';
import { ReactComponent as rostersSvg } from '../../resources/images/ico-rosters.svg';
import { ReactComponent as settingsSvg } from '../../resources/images/ico-settings.svg';
import { ReactComponent as signinSvg } from '../../resources/images/ico-signin.svg';
import { Divider, Menu, message, Modal } from 'antd';
import CustomContext from '../../context/CustomContext';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import { LegacyAuth } from '../../model/Entities';
import { ReactComponent as signoutSvg } from '../../resources/images/ico-signout.svg';
import { ReactComponent as InfoSvg } from '../../resources/images/info.svg';
import authService from '../../services/AuthService';
import styles from './NavigationComponent.module.scss';

class NavigationComponent extends React.Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    showSignOut = () => {
        const { intl } = this.props;

        Modal.confirm({
            title: intl.formatMessage({ id: 'signOut.title' }),
            onOk: this.signOut,
        });
    };

    signOut = async (): Promise<void> => {
        const { updateAuth } = this.context;
        const { intl } = this.props;

        try {
            this.setState({ loading: true });
            authService.signOut();
            updateAuth();
            this.props.history.push('/');
        } catch (error) {
            message.error(intl.formatMessage({ id: 'status.internalError' }));
        } finally {
            this.setState({ loading: false });
        }
    };

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    getPlayerId = (): string | undefined => {
        let playerId: string | undefined;
        if (this.props.playerId) {
            playerId = this.props.playerId;
        } else if (this.context.auth?.authorities.includes('ROLE_PLAYER')) {
            playerId = this.context.auth?.id;
        }
        return playerId;
    };

    /*** COMPONENTS ***/

    renderPublicDashboardMenu = (menu: React.ReactNode, collapsed: boolean): React.ReactNode | undefined => {
        const playerId = this.getPlayerId();
        if (menu) {
            return menu;
        } else {
            return (
                <>
                    {this.props.playerId && (
                        <>
                            <Menu.ItemGroup
                                key="public-player"
                                title={!collapsed && <FormattedMessage id="navigation.public.player" />}
                            >
                                <Menu.Item
                                    key="player-profile"
                                    icon={<Icon component={playerProfileSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/profile`)}
                                >
                                    <Link to={`/players/${playerId}/profile`}>
                                        <FormattedMessage id="navigation.public.player.profile" />
                                    </Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                            <Divider className={styles.divider} />
                        </>
                    )}

                    <Menu.ItemGroup
                        key="public-top100"
                        title={!collapsed && <FormattedMessage id="navigation.public.top100" />}
                    >
                        <Menu.Item
                            key="leaderboard"
                            icon={<Icon component={leaderboardSvg} />}
                            onClick={() => this.navigate('/leaderboard')}
                        >
                            <Link to="/leaderboard">
                                <FormattedMessage id="navigation.public.top100.leaderboard" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="events"
                            icon={<Icon component={eventsSvg} />}
                            onClick={() => this.navigate('/events')}
                        >
                            <Link to="/events">
                                <FormattedMessage id="navigation.public.top100.events" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="colleges-public"
                            icon={<Icon component={collegeSvg} />}
                            onClick={() => this.navigate(`/colleges`)}
                        >
                            <Link to="/colleges">
                                <FormattedMessage id="navigation.public.top100.colleges" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </>
            );
        }
    };

    renderAdminMenu = (menu: React.ReactNode, collapsed: boolean): React.ReactNode | undefined => {
        const playerId = this.getPlayerId();
        if (menu) {
            return menu;
        } else {
            return (
                <>
                    {this.props.playerId && (
                        <>
                            <Menu.ItemGroup
                                key="public-player"
                                title={!collapsed && <FormattedMessage id="navigation.public.player" />}
                            >
                                <Menu.Item
                                    key="player-profile"
                                    icon={<Icon component={playerProfileSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/profile`)}
                                >
                                    <Link to={`/players/${playerId}/profile`}>
                                        <FormattedMessage id="navigation.admin.dashboard.profile" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="player-data"
                                    icon={<Icon component={playerDataSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/data`)}
                                >
                                    <Link to={`/players/${playerId}/data`}>
                                        <FormattedMessage id="navigation.admin.dashboard.data" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="player-edit"
                                    icon={<Icon component={playerEditSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/edit`)}
                                >
                                    <Link to={`/players/${playerId}/edit`}>
                                        <FormattedMessage id="navigation.admin.dashboard.edit" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="player-media"
                                    icon={<Icon component={playerPhotosSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/media`)}
                                >
                                    <Link to={`/players/${playerId}/media`}>
                                        <FormattedMessage id="navigation.admin.dashboard.media" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="colleges"
                                    icon={<Icon component={collegeSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/colleges`)}
                                >
                                    <Link to={`/players/${playerId}/colleges`}>
                                        <FormattedMessage id="navigation.admin.dashboard.colleges" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="player-code"
                                    icon={<Icon component={playerCodeSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/code`)}
                                >
                                    <Link to={`/players/${playerId}/code`}>
                                        <FormattedMessage id="navigation.admin.dashboard.code" />
                                    </Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                            <Divider className={styles.divider} />
                        </>
                    )}

                    <Menu.ItemGroup
                        key="admin-dashboard"
                        title={!collapsed && <FormattedMessage id="navigation.admin.dashboard" />}
                    >
                        <Menu.Item
                            key="rosters"
                            icon={<Icon component={rostersSvg} />}
                            onClick={() => this.navigate('/rosters')}
                        >
                            <Link to="/rosters">
                                <FormattedMessage id="navigation.admin.dashboard.rosters" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="events"
                            icon={<Icon component={eventsSvg} />}
                            onClick={() => this.navigate('/events')}
                        >
                            <Link to="/events">
                                <FormattedMessage id="navigation.admin.dashboard.events" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="leaderboard"
                            icon={<Icon component={leaderboardSvg} />}
                            onClick={() => this.navigate('/leaderboard')}
                        >
                            <Link to="/leaderboard">
                                <FormattedMessage id="navigation.admin.dashboard.leaderboard" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="colleges-public"
                            icon={<Icon component={collegeSvg} />}
                            onClick={() => this.navigate('/colleges')}
                        >
                            <Link to="/colleges">
                                <FormattedMessage id="navigation.admin.dashboard.colleges" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Divider className={styles.divider} />
                    <Menu.ItemGroup
                        key="admin-addons"
                        title={!collapsed && <FormattedMessage id="navigation.admin.addons" />}
                    >
                        <Menu.Item
                            key="tutorials"
                            icon={<Icon component={adminTutorialSvg} />}
                            onClick={() => this.navigate('/tutorials')}
                        >
                            <Link to="/tutorials">
                                <FormattedMessage id="navigation.admin.addons.tutorials" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="codes"
                            icon={<Icon component={adminCodesSvg} />}
                            onClick={() => this.navigate('/codes')}
                        >
                            <Link to="/codes">
                                <FormattedMessage id="navigation.admin.addons.codes" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            disabled={true}
                            key="add-team"
                            icon={<Icon component={adminTeamSvg} />}
                            onClick={() => this.navigate('/add-team')}
                        >
                            <Link to="/add-team">
                                <FormattedMessage id="navigation.admin.addons.addTeam" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Divider className={styles.divider} />
                    <Menu.ItemGroup
                        key="events-admin"
                        title={!collapsed && <FormattedMessage id="navigation.admin.eventsAdmin" />}
                    >
                        <Menu.Item
                            key="event-creation"
                            icon={<Icon component={adminTutorialSvg} />}
                            onClick={() => this.navigate('/event-creation')}
                        >
                            <Link to="/event-creation">
                                <FormattedMessage id="navigation.admin.eventsAdmin.eventCreation" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="events-management"
                            icon={<Icon component={adminCodesSvg} />}
                            onClick={() => this.navigate('/events-management')}
                        >
                            <Link to="/events-management">
                                <FormattedMessage id="navigation.admin.eventsAdmin.eventsManagement" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="events-media"
                            icon={<Icon component={adminTeamSvg} />}
                            onClick={() => this.navigate('/events-media')}
                        >
                            <Link to="/events-media">
                                <FormattedMessage id="navigation.admin.eventsAdmin.eventsMedia" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Divider className={styles.divider} />
                    <Menu.ItemGroup key="admin" title={!collapsed && <FormattedMessage id="navigation.admin.admin" />}>
                        <Menu.Item
                            key="admin-organizations"
                            icon={<Icon component={adminCodesSvg} />}
                            onClick={() => this.navigate('/admin/organizations')}
                        >
                            <Link to="/admin/organizations">
                                <FormattedMessage id="navigation.admin.admin.organizations" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="admin-teams"
                            icon={<Icon component={adminCodesSvg} />}
                            onClick={() => this.navigate('/admin/teams')}
                        >
                            <Link to="/admin/teams">
                                <FormattedMessage id="navigation.admin.admin.teams" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="admin-coaches"
                            icon={<Icon component={adminTeamSvg} />}
                            onClick={() => this.navigate('/admin/coaches')}
                        >
                            <Link to="/admin/coaches">
                                <FormattedMessage id="navigation.admin.admin.coaches" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="admin-players"
                            icon={<Icon component={adminTutorialSvg} />}
                            onClick={() => this.navigate('/admin/players')}
                        >
                            <Link to="/admin/players">
                                <FormattedMessage id="navigation.admin.admin.players" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="admin-colleges"
                            icon={<Icon component={adminCodesSvg} />}
                            onClick={() => this.navigate('/admin/colleges')}
                        >
                            <Link to="/admin/colleges">
                                <FormattedMessage id="navigation.admin.admin.colleges" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="admin-users"
                            icon={<Icon component={adminTeamSvg} />}
                            onClick={() => this.navigate('/admin/users')}
                        >
                            <Link to="/admin/users">
                                <FormattedMessage id="navigation.admin.admin.users" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="admin-divisions"
                            icon={<Icon component={adminTeamSvg} />}
                            onClick={() => this.navigate('/admin/divisions')}
                        >
                            <Link to="/admin/divisions">
                                <FormattedMessage id="navigation.admin.admin.divisions" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="admin-awards"
                            icon={<Icon component={adminTeamSvg} />}
                            onClick={() => this.navigate('/admin/awards')}
                        >
                            <Link to="/admin/awards">
                                <FormattedMessage id="navigation.admin.admin.awards" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </>
            );
        }
    };

    renderOrganizationMenu = (menu: React.ReactNode, collapsed: boolean): React.ReactNode | undefined => {
        const playerId = this.getPlayerId();
        if (menu) {
            return menu;
        } else {
            return (
                <>
                    {this.props.playerId && (
                        <>
                            <Menu.ItemGroup
                                key="organization-player-dashboard"
                                title={!collapsed && <FormattedMessage id="navigation.organization.player" />}
                            >
                                <Menu.Item
                                    key="player-profile"
                                    icon={<Icon component={playerProfileSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/profile`)}
                                >
                                    <Link to={`/players/${playerId}/profile`}>
                                        <FormattedMessage id="navigation.organization.dashboard.profile" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="player-data"
                                    icon={<Icon component={playerDataSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/data`)}
                                >
                                    <Link to={`/players/${playerId}/data`}>
                                        <FormattedMessage id="navigation.organization.dashboard.data" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="player-edit"
                                    icon={<Icon component={playerEditSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/edit`)}
                                >
                                    <Link to={`/players/${playerId}/edit`}>
                                        <FormattedMessage id="navigation.organization.dashboard.edit" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="player-media"
                                    icon={<Icon component={playerPhotosSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/media`)}
                                >
                                    <Link to={`/players/${playerId}/media`}>
                                        <FormattedMessage id="navigation.organization.dashboard.media" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="colleges"
                                    icon={<Icon component={collegeSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/colleges`)}
                                >
                                    <Link to={`/players/${playerId}/colleges`}>
                                        <FormattedMessage id="navigation.organization.dashboard.colleges" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="player-code"
                                    icon={<Icon component={playerCodeSvg} />}
                                    onClick={() => this.navigate(`/players/${playerId}/code`)}
                                >
                                    <Link to={`/players/${playerId}/code`}>
                                        <FormattedMessage id="navigation.organization.dashboard.code" />
                                    </Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                            <Divider className={styles.divider} />
                        </>
                    )}

                    <Menu.ItemGroup
                        key="organization-dashboard"
                        title={!collapsed && <FormattedMessage id="navigation.organization.dashboard" />}
                    >
                        <Menu.Item
                            key="rosters"
                            icon={<Icon component={rostersSvg} />}
                            onClick={() => this.navigate('/rosters')}
                        >
                            <Link to="/rosters">
                                <FormattedMessage id="navigation.organization.dashboard.rosters" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="events"
                            icon={<Icon component={eventsSvg} />}
                            onClick={() => this.navigate('/events')}
                        >
                            <Link to="/events">
                                <FormattedMessage id="navigation.organization.dashboard.events" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="leaderboard"
                            icon={<Icon component={leaderboardSvg} />}
                            onClick={() => this.navigate('/leaderboard')}
                        >
                            <Link to="/leaderboard">
                                <FormattedMessage id="navigation.organization.dashboard.leaderboard" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="colleges-public"
                            icon={<Icon component={collegeSvg} />}
                            onClick={() => this.navigate('/colleges')}
                        >
                            <Link to="/colleges">
                                <FormattedMessage id="navigation.organization.dashboard.colleges" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Divider className={styles.divider} />
                    <Menu.ItemGroup
                        key="admin-addons"
                        title={!collapsed && <FormattedMessage id="navigation.organization.addons" />}
                    >
                        <Menu.Item
                            key="tutorials"
                            icon={<Icon component={adminTutorialSvg} />}
                            onClick={() => this.navigate('/tutorials')}
                        >
                            <Link to="/tutorials">
                                <FormattedMessage id="navigation.organization.addons.tutorials" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="codes"
                            icon={<Icon component={adminCodesSvg} />}
                            onClick={() => this.navigate('/codes')}
                        >
                            <Link to="/codes">
                                <FormattedMessage id="navigation.organization.addons.codes" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            disabled={true}
                            key="add-team"
                            icon={<Icon component={adminTeamSvg} />}
                            onClick={() => this.navigate('/add-team')}
                        >
                            <Link to="/add-team">
                                <FormattedMessage id="navigation.organization.addons.addTeam" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </>
            );
        }
    };

    renderPlayerMenu = (menu: React.ReactNode, collapsed: boolean): React.ReactNode | undefined => {
        if (menu) {
            return menu;
        } else {
            const playerId = this.getPlayerId();
            return (
                <>
                    <Menu.ItemGroup
                        key="player-dashboard"
                        title={!collapsed && <FormattedMessage id="navigation.player.dashboard" />}
                    >
                        <Menu.Item
                            key="player-profile"
                            icon={<Icon component={playerProfileSvg} />}
                            onClick={() => this.navigate(`/players/${playerId}/profile`)}
                        >
                            <Link to={`/players/${playerId}/profile`}>
                                <FormattedMessage id="navigation.player.dashboard.profile" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="player-data"
                            icon={<Icon component={playerDataSvg} />}
                            onClick={() => this.navigate(`/players/${playerId}/data`)}
                        >
                            <Link to={`/players/${playerId}/data`}>
                                <FormattedMessage id="navigation.player.dashboard.data" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="player-edit"
                            icon={<Icon component={playerEditSvg} />}
                            onClick={() => this.navigate(`/players/${playerId}/edit`)}
                        >
                            <Link to={`/players/${playerId}/edit`}>
                                <FormattedMessage id="navigation.player.dashboard.edit" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="player-media"
                            icon={<Icon component={playerPhotosSvg} />}
                            onClick={() => this.navigate(`/players/${playerId}/media`)}
                        >
                            <Link to={`/players/${playerId}/media`}>
                                <FormattedMessage id="navigation.player.dashboard.media" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="leaderboard"
                            icon={<Icon component={leaderboardSvg} />}
                            onClick={() => this.navigate('/leaderboard')}
                        >
                            <Link to="/leaderboard">
                                <FormattedMessage id="navigation.player.dashboard.leaderboard" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="combine"
                            icon={<Icon component={InfoSvg} />}
                            onClick={() => this.navigate('/combine')}
                        >
                            <Link to="/combine">
                                <FormattedMessage id="navigation.player.dashboard.combine" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="colleges"
                            icon={<Icon component={collegeSvg} />}
                            onClick={() => this.navigate(`/players/${playerId}/colleges`)}
                        >
                            <Link to={`/players/${playerId}/colleges`}>
                                <FormattedMessage id="navigation.player.dashboard.colleges" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="player-code"
                            icon={<Icon component={playerCodeSvg} />}
                            onClick={() => this.navigate(`/players/${playerId}/code`)}
                        >
                            <Link to={`/players/${playerId}/code`}>
                                <FormattedMessage id="navigation.player.dashboard.code" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="events"
                            icon={<Icon component={eventsSvg} />}
                            onClick={() => this.navigate('/events')}
                        >
                            <Link to="/events">
                                <FormattedMessage id="navigation.player.dashboard.events" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </>
            );
        }
    };

    renderAccountMenu = (auth: LegacyAuth | undefined, collapsed: boolean): React.ReactElement | undefined => {
        return (
            <>
                <Divider className={styles.divider} />

                <Menu.ItemGroup key="account" title={!collapsed && <FormattedMessage id="navigation.account" />}>
                    {auth && (
                        <Menu.Item
                            key="settings"
                            icon={<Icon component={settingsSvg} />}
                            onClick={() => this.navigate('/settings')}
                        >
                            <Link to="/settings">
                                <FormattedMessage id="navigation.account.settings" />
                            </Link>
                        </Menu.Item>
                    )}
                    {auth && (
                        <Menu.Item key="signout" icon={<Icon component={signoutSvg} />} onClick={this.showSignOut}>
                            <FormattedMessage id="navigation.account.signOut" />
                        </Menu.Item>
                    )}
                    {!auth && (
                        <Menu.Item
                            key="signin"
                            icon={<Icon component={signinSvg} />}
                            onClick={() => this.navigate('/signin')}
                        >
                            <Link to="/signin">
                                <FormattedMessage id="navigation.account.signIn" />
                            </Link>
                        </Menu.Item>
                    )}
                </Menu.ItemGroup>
            </>
        );
    };

    render() {
        const { auth } = this.context;
        const { renderMenu, page, collapsed } = this.props;
        const selectedKey = page ? [page] : undefined;
        const menu = renderMenu && renderMenu(collapsed);

        return (
            <Menu theme="dark" defaultSelectedKeys={selectedKey} mode="inline" inlineCollapsed={collapsed}>
                {!auth && this.renderPublicDashboardMenu(menu, collapsed)}
                {auth && auth.authorities.includes('ROLE_ADMIN') && this.renderAdminMenu(menu, collapsed)}
                {auth && auth.authorities.includes('ROLE_ORGANIZATION') && this.renderOrganizationMenu(menu, collapsed)}
                {auth && auth.authorities.includes('ROLE_PLAYER') && this.renderPlayerMenu(menu, collapsed)}
                {this.renderAccountMenu(auth, collapsed)}
            </Menu>
        );
    }
}
export default injectIntl(withRouter(NavigationComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    playerId?: string;
    renderMenu?: (collapsed: boolean) => React.ReactNode | undefined;
    page?: PageType;
    collapsed: boolean;
}

interface State {}
