import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Card, List, PageHeader, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import { TablePaginationConfig } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import organizationApi from '../../apis/OrganizationApi';
import tutorialApi from '../../apis/TutorialApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { LegacyPage } from '../../model/Elements';
import { Organization, Tutorial } from '../../model/Entities';
import { sports } from '../../model/Types';
import notificationService from '../../services/NotificationService';
import tableService from '../../services/TableService';
import styles from './TutorialsPage.module.scss';

class TutorialsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { organizations: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const responses = await Promise.all([
                organizationApi.listAll(),
                tutorialApi.list(0, tableService.pageSize, 'name', true),
            ]);
            const organizations = responses[0];
            const tutorialsPage = responses[1];

            this.setState({ organizations, tutorialsPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const tutorialsPage = await tutorialApi.list(page - 1, pageSize, sortField, sortOrder, searchText);
            this.setState({ tutorialsPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    search = async (searchText: string) => {
        const { tutorialsPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = tutorialsPage!.size;
            const sortField = tutorialsPage!.sort.field!;
            const sortOrder = tutorialsPage!.sort.order!;
            const tutorialsPageNew = await tutorialApi.list(0, pageSize, sortField, sortOrder, searchText);
            this.setState({ tutorialsPage: tutorialsPageNew, searchText, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return <PageHeader title={<Search size="large" className={styles.search} />} />;
        } else {
            return (
                <>
                    <Button type="ghost" icon={<FilterOutlined />} />
                    <Button type="ghost" icon={<SearchOutlined />} />
                </>
            );
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        return (
            <>
                {desktop && this.renderToolbar()}
                {this.renderList(desktop)}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { organizations } = this.state;

        const sportOptions = sports.map((sport) => (
            <Select.Option key={sport} value={sport}>
                <FormattedMessage id={sport} />
            </Select.Option>
        ));
        const organizationOptions = organizations.map((organization) => (
            <Select.Option key={organization.id} value={organization.id!}>
                {organization.name}
            </Select.Option>
        ));

        return (
            <PageHeader
                title={<FormattedMessage id="tutorials.title" />}
                extra={[
                    <Select
                        key="sports"
                        size="large"
                        className={styles.sports}
                        placeholder={<FormattedMessage id="tutorials.toolbar.sports" />}
                        allowClear
                    >
                        {sportOptions}
                    </Select>,
                    <Select
                        key="organizations"
                        size="large"
                        className={styles.organizations}
                        placeholder={<FormattedMessage id="tutorials.toolbar.organizations" />}
                        allowClear
                    >
                        {organizationOptions}
                    </Select>,
                ]}
                className={styles.toolbar}
            ></PageHeader>
        );
    };

    renderList = (desktop: boolean): React.ReactElement | undefined => {
        const { tutorialsPage, loading } = this.state;
        const items = tutorialsPage ? tutorialsPage.content : [];

        return (
            <List
                grid={{
                    gutter: 16,
                    xs: 2,
                    sm: 3,
                    md: 3,
                    lg: 4,
                    xl: 5,
                    xxl: 6,
                }}
                dataSource={items}
                renderItem={(tutorial) => (
                    <List.Item>
                        <Card className={styles.card} cover={<div className={styles.image} />}>
                            <Card.Meta title={tutorial.name} />
                        </Card>
                    </List.Item>
                )}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent page="tutorials" content={this.renderContent} header={this.renderHeader} />;
    }
}
export default injectIntl(TutorialsPage);

interface Props extends WrappedComponentProps {}

interface State {
    tutorialsPage?: LegacyPage<Tutorial>;
    organizations: Organization[];
    loading?: boolean;
    searchText?: string;
}
