// import authApi from '../api/AuthApi';
import Auth from '@aws-amplify/auth';
import userApi from '../apis/UserApi';
import { LegacyAuth, User } from '../model/Entities';
import { AuthorityType } from '../model/Types';

class AuthService {
    auth: LegacyAuth | undefined;
    readonly authority = 'authority';

    get = async (): Promise<LegacyAuth | undefined> => {
        try {
            const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
            if (user) {
                const currentUser = await userApi.getFromCognito();
                const authority = (localStorage.getItem(this.authority) as AuthorityType) || 'ROLE_ADMIN';
                this.auth = this.createAuth(currentUser, authority);
            }
        } catch {
            this.auth = undefined;
        }

        return this.auth;
    };

    signIn = async (username: string, password: string, authority: AuthorityType): Promise<LegacyAuth> => {
        await Auth.signIn(username, password);
        const currentUser = await userApi.getFromCognito();
        this.auth = this.createAuth(currentUser, authority);
        localStorage.setItem(this.authority, authority);

        return this.auth;
    };

    signOut = async () => {
        await Auth.signOut();
        this.auth = undefined;
        localStorage.removeItem(this.authority);
    };

    forgotPassword = async (username: string) => {
        await Auth.forgotPassword(username);
    };

    resetPassword = async (username: string, code: string, password: string) => {
        await Auth.forgotPasswordSubmit(username, code, password);
        return await this.signIn(username, password, 'ROLE_PLAYER'); // TODO: to be removed
    };

    getAccessToken = async () => {
        const session = await Auth.currentSession();
        return session.getAccessToken().getJwtToken();
    };

    /**
     * Returns the auth from the cognito user.
     * @param user the cognito user
     * @param authority the authority
     * @returns the auth
     */
    private createAuth(user: User, authority: AuthorityType): LegacyAuth {
        return {
            id: user.id!,
            email: user.profile?.email!,
            firstName: user.profile?.givenName,
            lastName: user.profile?.familyName,
            authorities: [authority],
        };
    }
}

const authService: AuthService = new AuthService();
export default authService;
