import { FormInstance, Modal } from 'antd';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import ReactPlayer from 'react-player';
import CustomContext from '../../../context/CustomContext';
import notificationService from '../../../services/NotificationService';

class AddMediaComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    /*** COMPONENTS ***/

    render() {
        return (
            <Modal footer={[]} visible={true} onCancel={this.cancel}>
                <ReactPlayer controls={true} width="10" url="https://www.youtube.com/watch?v=ysz5S6PUM-U" />
            </Modal>
        );
    }
}
export default injectIntl(AddMediaComponent);

interface Props extends WrappedComponentProps {
    onCancel: () => void;
}

interface State {
    loading?: boolean;
}
