import React, { useContext } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import CustomContext from '../../context/CustomContext';

/**
 * This component represents a protected route. It redirect to the home page if the user is not authorized to view a route.
 * @param props - the route props
 */
const DefaultRoute: React.FC<RouteProps> = (props: RouteProps) => {
    const context = useContext(CustomContext);
    const { auth } = context;

    if (auth && auth.authorities.includes('ROLE_ADMIN')) {
        return <Redirect to="/rosters" />;
    } else if (auth && auth.authorities.includes('ROLE_PLAYER')) {
        const playerId = context.auth?.id;
        return <Redirect to={`/players/${playerId}/profile`} />;
    } else if (auth && auth.authorities.includes('ROLE_ORGANIZATION')) {
        return <Redirect to="/rosters" />;
    } else {
        return <Redirect to="/leaderboard" />;
    }
};
export default DefaultRoute;
