import Icon from '@ant-design/icons';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { Button, Col, Form, FormInstance, Input, message, Row } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import awardApi from '../../../../apis/AwardApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Award } from '../../../../model/Entities';
import { ReactComponent as SaveSvg } from '../../../../resources/images/ico-save.svg';
import notificationService from '../../../../services/NotificationService';
import styles from './AwardPage.module.scss';

class AwardPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            if (this.props.match.params.id === 'new') {
                await this.new();
            } else {
                await this.get(this.props.match.params.id);
            }
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };

    new = async () => {
        const award: Award = {
            name: '',
        };

        this.setState({ award });
        this.formRef.current!.setFieldsValue(award);
    };

    get = async (id: string) => {
        const award = await awardApi.get(id);

        this.setState({ award });
        this.formRef.current!.setFieldsValue(award);
    };

    save = async (values: any) => {
        try {
            this.setState({ loading: true });
            let award: Award = Object.assign({}, this.state.award, values);
            award = award.id ? await awardApi.update(award) : await awardApi.create(award);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ award });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'award.status.duplicate' },
            ]);
        } finally {
            this.setState({ loading: false });
        }
    };

    delete = async () => {
        const { award } = this.state;
        try {
            this.setState({ loading: true });
            await awardApi.delete(award!);
            this.props.history.push(`/admin/awards`);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        return (
            <>
                {this.renderToolbar()}
                <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="award.name" />}
                                name="name"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="award.type" />} name="type">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="award.description" />} name="description">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="award.url" />} name="url">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="award.date" />} name="date">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="award.combineId" />} name="combineId">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="award.endorsementCount" />} name="endorsementCount">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="award.userId" />} name="userId">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className={styles.buttons}>
                        <Link to="/admin/awards">
                            <Button size="large" className={styles.back}>
                                <FormattedMessage id="award.back" />
                            </Button>
                        </Link>
                        {this.props.match.params.id !== 'new' && (
                            <Button
                                size="large"
                                icon={<DeleteOutlined />}
                                className={styles.delete}
                                onClick={() => this.delete()}
                            >
                                <FormattedMessage id="award.delete" />
                            </Button>
                        )}
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className={styles.save}
                            icon={<Icon component={SaveSvg} />}
                            loading={loading}
                        >
                            <FormattedMessage id="award.save" />
                        </Button>
                    </div>
                </Form>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        if (this.props.match.params.id === 'new') {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="awards.addtitle" />
                    </h1>
                </div>
            );
        } else {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="awards.edittitle" />
                    </h1>
                </div>
            );
        }
    };

    render() {
        return <LayoutComponent page="edit-award" content={this.renderContent} />;
    }
}
export default injectIntl(AwardPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    award?: Award;
    loading?: boolean;
}
