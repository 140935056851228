import { LegacyPage } from '../model/Elements';
import { Tutorial } from '../model/Entities';

class TutorialApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<LegacyPage<Tutorial>> => {
        return Promise.resolve({
            content: tutorials,
            totalElements: 2,
            totalPages: 1,
            size: 2,
            number: 1,
            numberOfElements: 2,
            first: true,
            last: true,
            empty: false,
            pageable: {
                pageNumber: page,
                pageSize: size,
            },
            sort: {
                unsorted: false,
                sorted: true,
                empty: false,
                field: sortField,
                order: sortOrder,
            },
        });
    };
}

const tutorialApi: TutorialApi = new TutorialApi();
export default tutorialApi;

// mock data
const tutorials: Tutorial[] = [...Array(10)].map((v, i) => ({
    id: i,
    name: `Tutorial ${i}`,
}));
