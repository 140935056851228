import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Form, FormInstance, List, PageHeader, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import { TablePaginationConfig } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import mediaApi from '../../apis/MediaApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { LegacyPage } from '../../model/Elements';
import { Media } from '../../model/Entities';
import notificationService from '../../services/NotificationService';
import tableService from '../../services/TableService';
import AddMediaComponent from './AddMediaComponent/AddMediaComponent';
import styles from './MediaPage.module.scss';
import MediaPlayerComponent from './MediaPlayerComponent/MediaPlayerComponent';

class MediaPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const mediaPage = await mediaApi.list(0, tableService.pageSize, 'name', true);

            this.setState({ playerModalVisible: false, modalVisible: false, mediaPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const mediaPage = await mediaApi.list(page - 1, pageSize, sortField, sortOrder, searchText);
            this.setState({ mediaPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    search = async (searchText: string) => {
        const { mediaPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = mediaPage!.size;
            const sortField = mediaPage!.sort.field!;
            const sortOrder = mediaPage!.sort.order!;
            const mediaPageNew = await mediaApi.list(0, pageSize, sortField, sortOrder, searchText);
            this.setState({ mediaPage: mediaPageNew, searchText, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    showModal = (modalVisible: boolean) => {
        this.setState({ modalVisible });
    };

    showPlayerModal = (playerModalVisible: boolean) => {
        this.setState({ playerModalVisible });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return (
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={2}>
                            <Avatar size={64} icon={<UserOutlined />} />
                        </Col>
                        <Col xs={24} md={3}>
                            <div>
                                <h1>Carter Butcher</h1>
                            </div>
                        </Col>
                        <Col xs={24} md={2}>
                            <Form.Item name="select">
                                <Button shape="round">
                                    <FormattedMessage id="player.media.select" />
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={3}>
                            <Form.Item name="add">
                                <Button icon={<PlusOutlined />} shape="round" onClick={() => this.showModal(true)}>
                                    <FormattedMessage id="player.media.add" />
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={4}>
                            <div>
                                <h1>
                                    <FormattedMessage id="player.media.allPhotosVideos" />
                                </h1>
                            </div>
                        </Col>
                        <Col xs={24} md={1}>
                            <Form.Item name="all">
                                <Button shape="round">
                                    <FormattedMessage id="player.media.all" />
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={2}>
                            <Form.Item name="photos">
                                <Button shape="round">
                                    <FormattedMessage id="player.media.photos" />
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={3}>
                            <Form.Item name="videos">
                                <Button shape="round">
                                    <FormattedMessage id="player.media.videos" />
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <PageHeader title={<Search size="middle" className={styles.search} />} />
                        </Col>
                    </Row>
                </Form>
            );
        } else {
            return (
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col span={10}>
                            <Form.Item name="select">
                                <Button shape="round">
                                    <FormattedMessage id="player.media.select" />
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item name="add">
                                <Button
                                    shape="round"
                                    onClick={() => this.showModal(true)}
                                    type={'text'}
                                    icon={<PlusOutlined />}
                                ></Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            );
        }
    };

    renderList = (desktop: boolean): React.ReactElement | undefined => {
        const { playerModalVisible, modalVisible, mediaPage, loading } = this.state;
        const items = mediaPage ? mediaPage.content : [];

        return (
            <>
                <AddMediaComponent visible={modalVisible} onCancel={() => this.showModal(false)} />
                {playerModalVisible && <MediaPlayerComponent onCancel={() => this.showPlayerModal(false)} />}
                <List
                    grid={{
                        gutter: 16,
                        xs: 2,
                        sm: 3,
                        md: 3,
                        lg: 4,
                        xl: 5,
                        xxl: 6,
                    }}
                    dataSource={items}
                    renderItem={(media) => (
                        <List.Item>
                            <Card
                                className={styles.card}
                                cover={<div onClick={() => this.showPlayerModal(true)} className={styles.image} />}
                            >
                                <Card.Meta title={media.name} />
                            </Card>
                        </List.Item>
                    )}
                    loading={loading}
                />
            </>
        );
    };

    render() {
        const playerId = this.props.match.params.id;
        return (
            <LayoutComponent
                page="player-media"
                content={this.renderList}
                header={this.renderHeader}
                playerId={playerId}
            />
        );
    }
}
export default injectIntl(MediaPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    playerModalVisible?: boolean;
    modalVisible?: boolean;
    mediaPage?: LegacyPage<Media>;
    loading?: boolean;
    searchText?: string;
}
