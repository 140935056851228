import Icon from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Divider, Form, FormInstance, Input, Menu, Row, Select, Space } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import eventApi from '../../../../apis/EventApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Event } from '../../../../model/Entities';
import { genders, states } from '../../../../model/Types';
import { ReactComponent as arrowBackSvg } from '../../../../resources/images/ico-arrowBack.svg';
import { ReactComponent as backSvg } from '../../../../resources/images/ico-back.svg';
import { ReactComponent as step1Svg } from '../../../../resources/images/ico-1.svg';
import { ReactComponent as step2Svg } from '../../../../resources/images/ico-2.svg';
import { ReactComponent as step3Svg } from '../../../../resources/images/ico-3.svg';
import { ReactComponent as step4Svg } from '../../../../resources/images/ico-4.svg';
import styles from './EventRegistrationPage.module.scss';

class EventRegistrationPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const event = await eventApi.getEvent(+this.props.match.params.id);
            this.setState({ event });
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (!desktop) {
            return (
                <div className={styles.header}>
                    <Link to={`/events/${+this.props.match.params.id}/summary`}>
                        <Button type="text" size="small" className={styles.back} icon={<Icon component={arrowBackSvg} />}></Button>
                    </Link>

                    <div className={styles.mobileHeaderBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <h1>
                        <FormattedMessage id="events.event.parent.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="events.event.parent.description" />
                    </p>
                </div>
            );
        }
        else{
            return(
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <h1>
                        <FormattedMessage id="events.event.parent.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="events.event.parent.description" />
                    </p>
                </div>
            )
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { loading, event } = this.state;

        const gendersOptions = genders.map((gender) => (
            <Select.Option key={gender} value={gender}>
                <FormattedMessage id={gender} />
            </Select.Option>
        ));

        const statesOptions = states.map((state) => (
            <Select.Option key={state} value={state}>
                <FormattedMessage id={state} />
            </Select.Option>
        ));

        return (
            <div className={styles.form}>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.firstName" />}
                                name="firstName"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.lastName" />}
                                name="lastName"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.email" />}
                                name="email"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.phone" />}
                                name="phone"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.birth" />}
                                name="birthDate"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Space style={{ width: '100%' }} direction="vertical">
                                    <DatePicker />
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.gender" />}
                                name="gender"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select>{gendersOptions}</Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <h2>
                            <FormattedMessage id="events.event.parent.residentialAddress" />
                        </h2>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.address" />}
                                name="address"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.city" />}
                                name="city"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={50} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.state" />}
                                name="state"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select>{statesOptions}</Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={5}>
                            <Form.Item
                                label={<FormattedMessage id="events.event.parent.postalCode" />}
                                name="postalCode"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={50} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Form.Item label={<FormattedMessage id="events.event.parent.emailUpdates" />} name="college" className={styles.newsletter}>
                                <Checkbox>
                                    <FormattedMessage id="events.event.parent.emailMe" />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="recaptcha">
                                <div>re-captcha</div>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item className="buttons">
                        <p className={styles.disclaimer}>By continuing, you agree to this site's terms of service and privacy policy.</p>
                    </Form.Item>

                    <Form.Item className="buttons">
                        <Space size={15}>
                            <Link to={`/events/${event?.id}/child-profile`}>
                                <Button type="primary" htmlType="submit" size="large" loading={loading}>
                                    <FormattedMessage id="events.event.parent.completeProfile" tagName="span" />
                                </Button>
                            </Link>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { event } = this.state;
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item key="event-dashboard" icon={<Icon component={backSvg} />}>
                        <Link to={`/events/${event?.id}/summary`}>
                            <FormattedMessage id="events.event.back" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className={styles.divider} />

                <Menu.ItemGroup
                    key="event-register-menu"
                    title={!collapsed && <FormattedMessage id="events.event.registration" />}
                >
                    <Menu.Item key="event-register" icon={<Icon component={step1Svg} />}>
                        <Link to={`/events/${event?.id}/register`}>
                            <FormattedMessage id="events.event.parentProfile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-child" icon={<Icon component={step2Svg} />}>
                        <Link to={`/events/${event?.id}/child-profile`}>
                            <FormattedMessage id="events.event.childProfile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-payments" icon={<Icon component={step3Svg} />}>
                        <Link to={`/events/${event?.id}/waivers`}>
                            <FormattedMessage id="events.event.payments" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="event-confirmation" icon={<Icon component={step4Svg} />}>
                        <Link to={`/events/${event?.id}/confirmation`}>
                            <FormattedMessage id="events.event.confirmation" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    };

    render() {
        return (
            <LayoutComponent
                page="event-register"
                menu={this.renderMenu}
                content={this.renderContent}
                header={this.renderHeader}
            />
        );
    }
}
export default injectIntl(EventRegistrationPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    event?: Event;
    loading?: boolean;
}
