import React, { Component } from 'react';
import styles from './SignInPage.module.scss';
import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../context/CustomContext';
import authService from '../../services/AuthService';
import { authorities } from '../../model/Types';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

class SignInPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    signIn = async (values: any): Promise<void> => {
        const { updateAuth } = this.context;
        const { intl } = this.props;

        try {
            this.setState({ loading: true });
            const auth = await authService.signIn(values.username, values.password, values.authority);
            updateAuth(auth);
            this.props.history.push('/');
        } catch (error) {
            message.error(intl.formatMessage({ id: 'signIn.status.error' }));
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <div className={styles.container}>
                <img src={process.env.PUBLIC_URL + '/img/top100-logo.png'} alt="" />
                <div className={styles.panel}>
                    <div className={styles.shadow}></div>
                    <div className={styles.body}>{this.renderForm()}</div>
                </div>
            </div>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { loading } = this.state;
        const authorityOptions = authorities.map((authority) => (
            <Select.Option key={authority} value={authority}>
                {authority}
            </Select.Option>
        ));

        return (
            <>
                <Row>
                    <Col>
                        <h1>
                            <FormattedMessage id="signIn.title" />
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="link" className={styles.link}>
                            <FormattedMessage id="signIn.joinTeam" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form ref={this.formRef} onFinish={this.signIn} colon={false} layout="vertical">
                            <Form.Item
                                label={<FormattedMessage id="signIn.username" />}
                                name="username"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                required={false}
                                initialValue="info@top100sports.com"
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="signIn.password" />}
                                name="password"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                required={false}
                            >
                                <Input type="password" maxLength={25} size="large" />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="signIn.authority" />}
                                name="authority"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                required={false}
                                initialValue="ROLE_ADMIN"
                            >
                                <Select size="large">{authorityOptions}</Select>
                            </Form.Item>
                            <Form.Item className={styles.buttons}>
                                <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                                    <FormattedMessage id="signIn.signIn" tagName="span" />
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to="/forgot-password">
                            <Button type="link" className={styles.password}>
                                <FormattedMessage id="signIn.forgotPassword" />
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        return <>{this.renderContent()}</>;
    }
}
export default injectIntl(SignInPage);

interface Props extends WrappedComponentProps, RouteComponentProps {}

interface State {
    loading?: boolean;
}
