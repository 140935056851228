import Icon from '@ant-design/icons';
import { FilterOutlined } from '@ant-design/icons/lib/icons';
import { Button, Checkbox, Divider, Form, FormInstance, List, Menu, Row, Select } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import eventApi from '../../apis/EventApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { LegacyPage } from '../../model/Elements';
import { Event } from '../../model/Entities';
import { levels, locations, seasons, sports } from '../../model/Types';
import { ReactComponent as eventsSvg } from '../../resources/images/ico-events.svg';
import { ReactComponent as eventsCompletedSvg } from '../../resources/images/ico-tasks.svg';
import { ReactComponent as eventsLiveSvg } from '../../resources/images/ico-eventsLive.svg';
import { ReactComponent as dashboardSvg } from '../../resources/images/ico-dashboard.svg';
import { ReactComponent as softballSvg } from '../../resources/images/sportSoftball.svg';
import notificationService from '../../services/NotificationService';
import tableService from '../../services/TableService';
import styles from './EventsPage.module.scss';

class EventsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const eventsPage = await eventApi.list(0, tableService.pageSize, 'name', true);
            this.setState({ eventsPage, loading: false });
        } catch (error) {
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const eventsPage = await eventApi.list(page - 1, pageSize, sortField, sortOrder, searchText);
            this.setState({ eventsPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    search = async (searchText: string) => {
        const { eventsPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = eventsPage!.size;
            const sortField = eventsPage!.sort.field!;
            const sortOrder = eventsPage!.sort.order!;
            const eventsPageNew = await eventApi.list(0, pageSize, sortField, sortOrder, searchText);
            this.setState({ eventsPage: eventsPageNew, searchText, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return (
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <h1>
                        <FormattedMessage id="player.events.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="player.events.description" />
                    </p>
                </div>
            );
        } else {
            return <Button type="ghost" icon={<FilterOutlined />} />;
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return this.renderDesktop();
        } else {
            return this.renderMobile();
        }
    };

    // Method for render in desktop
    renderDesktop = (): React.ReactElement | undefined => {
        const { eventsPage, loading } = this.state;
        const items = eventsPage ? eventsPage.content : [];

        const sportsOptions = sports.map((sport) => (
            <Select.Option key={sport} value={sport}>
                <FormattedMessage id={sport} />
            </Select.Option>
        ));

        const seasonsOptions = seasons.map((season) => (
            <Select.Option key={season} value={season}>
                <FormattedMessage id={season} />
            </Select.Option>
        ));

        const locationsOptions = locations.map((location) => (
            <Select.Option key={location} value={location}>
                <FormattedMessage id={location} />
            </Select.Option>
        ));

        const levelsOptions = levels.map((level) => (
            <Select.Option key={level} value={level}>
                <FormattedMessage id={level} />
            </Select.Option>
        ));

        return (
            <>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <div className={styles.subheader}>
                        <div className={styles.shcontent}>
                            <div className={styles.flex}>
                                <Form.Item name="sports">
                                    <Select
                                        className={styles.select}
                                        placeholder={<FormattedMessage id="player.events.sports" />}
                                        style={{ width: 130 }}
                                    >
                                        {sportsOptions}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="seasons">
                                    <Select
                                        className={styles.select}
                                        placeholder={<FormattedMessage id="player.events.seasons" />}
                                        style={{ width: 130 }}
                                    >
                                        {seasonsOptions}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className={styles.days}>
                                <Form.Item name="su">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.su" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="mo">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.mo" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="tu">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.tu" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="we">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.we" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="th">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.th" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="fr">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.fr" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="sa">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.sa" />
                                    </Checkbox>
                                </Form.Item>
                            </div>

                            <div className={styles.flex}>
                                <Form.Item name="locations">
                                    <Select
                                        className={styles.select}
                                        placeholder={<FormattedMessage id="player.events.locations" />}
                                        style={{ width: 150 }}
                                    >
                                        {locationsOptions}
                                    </Select>
                                </Form.Item>

                                <Form.Item name="levels">
                                    <Select
                                        className={styles.select}
                                        placeholder={<FormattedMessage id="player.events.levels" />}
                                        style={{ width: 130 }}
                                    >
                                        {levelsOptions}
                                    </Select>
                                </Form.Item>
                            </div>

                            <Form.Item name="filter">
                                <Button shape={'round'} type="primary" size={'large'} className={styles.button}>
                                    <FormattedMessage id="player.events.filter" />
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>

                <List
                    className={styles.eventsList}
                    dataSource={items}
                    renderItem={(event) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <div className={styles.details}>
                                        {/*<Avatar src="error" /> */}
                                        <div className={styles.address}>
                                            <p>1800 Broadway Streer, Unit 2C Buffalo, NY 14212</p>
                                        </div>
                                        <div className={styles.date}>
                                            <div>17</div>
                                            <div>
                                                <p>Apr</p>
                                                <p>Sat, 6:00 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                className={styles.event}
                                title={
                                    <div className={styles.eventTitle}>
                                        <div className={styles.category}>
                                            <Icon component={softballSvg} />
                                            Softball - Female
                                        </div>
                                        <Link to={`/events/${event.id}/summary`}>{event.name}</Link>
                                    </div>
                                }
                                description={
                                    <>
                                        <Row className={styles.eventDescription}>
                                            <div className={styles.eventFlex}>
                                                <List.Item.Meta
                                                    title={<FormattedMessage id="player.events.registrationDate" />}
                                                    description={event.registrationDates}
                                                />
                                                <List.Item.Meta
                                                    title={<FormattedMessage id="player.events.groupFees" />}
                                                    description={event.groupFees}
                                                />
                                                <List.Item.Meta
                                                    title={<FormattedMessage id="player.events.sponsor" />}
                                                    description={event.sponsor}
                                                />
                                            </div>
                                            <Button
                                                size="large"
                                                type="primary"
                                                className={styles.register}
                                                shape="round"
                                            >
                                                <Link to={`/events/${event?.id}/register`}>
                                                    <FormattedMessage id="player.events.register" />
                                                </Link>
                                            </Button>
                                        </Row>
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                    loading={loading}
                />
            </>
        );
    };

    // Method for render in mobiles
    renderMobile = (): React.ReactElement | undefined => {
        const { eventsPage, loading } = this.state;
        const items = eventsPage ? eventsPage.content : [];

        return (
            <>
                <div className={styles.mobileHeaderBackground}>
                    <div className={styles.headerBackgroundGradient}></div>
                    <div className={styles.headerBackgroundImage}></div>
                </div>
                <Form ref={this.formRef} colon={false} layout="vertical" className={styles.topNavi}>
                    <div className={styles.buttonDiv}>
                        <Button size="large" shape="round" className={styles.events}>
                            <FormattedMessage id="player.events.events" />
                        </Button>
                    </div>
                    <div className={styles.buttonDiv}>
                        <Button size="large" shape="round" className={styles.tasks}>
                            <FormattedMessage id="player.events.completed" />
                        </Button>
                    </div>
                </Form>

                <List
                    className={styles.eventsListMobile}
                    dataSource={items}
                    renderItem={(event) => (
                        <List.Item className={styles.event}>
                            <List.Item.Meta
                                description={
                                    <div className={styles.headerFlex}>
                                        <div className={styles.date}>
                                            <div>17</div>
                                            <div>
                                                <p>Apr</p>
                                                <p>Sat, 6:00 PM</p>
                                            </div>
                                        </div>
                                        <List.Item.Meta
                                            title={<FormattedMessage id="player.events.sponsor" />}
                                            description={event.sponsor}
                                        />
                                    </div>
                                }
                            />
                            <List.Item.Meta
                                className={styles.eventDetails}
                                title={
                                    <div className={styles.eventTitle}>
                                        <div className={styles.category}>
                                            <Icon component={softballSvg} />
                                            Softball - Female
                                        </div>
                                        <Link to={`/events/${event.id}/summary`}>{event.name}</Link>
                                    </div>
                                }
                                description={
                                    <>
                                        <Row className={styles.eventDescription}>
                                            <List.Item.Meta
                                                title={<FormattedMessage id="player.events.registrationDate" />}
                                                description={event.registrationDates}
                                            />
                                        </Row>
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                    loading={loading}
                />
            </>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item key="events-dashboard" icon={<Icon component={dashboardSvg} />}>
                        <Link to="/">
                            <FormattedMessage id="navigation.admin.dashboard" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                <Menu.ItemGroup key="events-menu" title={!collapsed && <FormattedMessage id="events.registration" />}>
                    <Menu.Item key="events" icon={<Icon component={eventsSvg} />}>
                        <Link to={`/events`}>
                            <FormattedMessage id="events.events" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="events-completed"
                        className={styles.ico24}
                        icon={<Icon component={eventsCompletedSvg} />}
                    >
                        <FormattedMessage id="events.completed" />
                    </Menu.Item>
                    <Menu.Item key="events-live" className={styles.ico24} icon={<Icon component={eventsLiveSvg} />}>
                        <FormattedMessage id="events.live" />
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    };

    render() {
        return (
            <LayoutComponent
                page="events"
                menu={this.renderMenu}
                content={this.renderContent}
                header={this.renderHeader}
            />
        );
    }
}
export default injectIntl(EventsPage);

interface Props extends WrappedComponentProps {}

interface State {
    eventsPage?: LegacyPage<Event>;
    loading?: boolean;
    searchText?: string;
}
