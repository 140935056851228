import Icon from '@ant-design/icons';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { Avatar, Button, Card, List, Space } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import playerApi from '../../../../apis/PlayerApi';
import CustomContext from '../../../../context/CustomContext';
import { Player, Team } from '../../../../model/Entities';
import { DisplayType } from '../../../../model/Types';
import { ReactComponent as editSvg } from '../../../../resources/images/ico-edit.svg';
import notificationService from '../../../../services/NotificationService';
import styles from '../../RostersPage.module.scss';
import ChangeProfileComponent from '../ChangeProfileComponent/ChangeProfileComponent';
import ProfilePhotoComponent from '../ProfilePhotoComponent/ProfilePhotoComponent';

class TeamComponent extends React.Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const players = await playerApi.listByTeam(this.props.id);
            const team = this.props.team;

            this.setState({ players, team });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    showModal = (visible: boolean) => {
        this.setState({ modalVisible: visible });
    };

    showModalChange = (visible: boolean) => {
        this.setState({ modalChangeVisible: visible });
    };

    /*** COMPONENTS ***/

    renderCards = (): React.ReactElement | undefined => {
        const { players, team, loading } = this.state;
        const items = players;

        return (
            <List
                header={[
                    <Space className={styles.navi}>
                        <h3 className={styles.teamName}>{team?.name}</h3>
                        <Button>
                            <FormattedMessage id="roster.team.athletes" />
                        </Button>
                        <Button>
                            <FormattedMessage id="roster.team.coaches" />
                        </Button>
                        <Button className={styles.manage} icon={<Icon component={editSvg} />}>
                            <FormattedMessage id="roster.team.manageRoster" />
                        </Button>
                    </Space>,
                ]}
                grid={{
                    gutter: 30,
                    xs: 2,
                    sm: 3,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                }}
                dataSource={items}
                renderItem={(player) => (
                    <List.Item key={player.id}>
                        <Card
                            className={styles.card}
                            cover={
                                <div className={styles.imageHigh}>
                                    <Button className={styles.edit} size="small" shape="round">
                                        <FormattedMessage id="roster.team.editPlayer" />
                                    </Button>
                                    <div onClick={() => this.showModal(true)} className={styles.image} />
                                </div>
                            }
                        >
                            <Card.Meta
                                title={
                                    <div onClick={() => this.showModalChange(true)}>
                                        {player.firstName} {player.lastName}
                                    </div>
                                }
                                description={
                                    <div onClick={() => this.showModalChange(true)} className={styles.description}>
                                        <ul className={styles.stats}>
                                            <li className={styles.place}>3rd</li>
                                            <li className={styles.height}>4'6"</li>
                                            <li className={styles.weight}>75 lbs</li>
                                        </ul>

                                        <div className={styles.misc}>
                                            <div className={styles.number}>#27</div>
                                            <p>C, 2BV, SS, P</p>
                                        </div>
                                    </div>
                                }
                            />
                        </Card>
                    </List.Item>
                )}
                className={styles.teams}
                loading={loading}
            />
        );
    };

    renderList = (): React.ReactElement | undefined => {
        const { players, team, loading } = this.state;
        const items = players;

        return (
            <List
                header={[
                    <Space className={styles.navi}>
                        <h3 className={styles.teamName}>{team?.name}</h3>
                        <Button>
                            <FormattedMessage id="roster.team.athletes" />
                        </Button>
                        <Button>
                            <FormattedMessage id="roster.team.coaches" />
                        </Button>
                        <Button icon={<EditOutlined />}>
                            <FormattedMessage id="roster.team.manageRoster" />
                        </Button>
                    </Space>,
                ]}
                dataSource={items}
                renderItem={(player) => (
                    <>
                        <List.Item key={player.id} className={styles.list}>
                            <List.Item.Meta
                                avatar={<Avatar className={styles.avatar} size={84} src="error" />}
                                description={
                                    <div className={styles.description}>
                                        <div className={styles.playerName}>
                                            <h3>
                                                {player.firstName} {player.lastName}
                                            </h3>
                                            <div className={styles.misc}>
                                                <div className={styles.number}>#27</div>
                                                <p>C, 2BV, SS, P</p>
                                            </div>
                                        </div>
                                        <div className={styles.playerStats}>
                                            <ul className={styles.stats}>
                                                <li className={styles.place}>3rd</li>
                                                <li className={styles.height}>4'6"</li>
                                                <li className={styles.weight}>75 lbs</li>
                                            </ul>
                                            <Button size="large" shape="round">
                                                <FormattedMessage id="roster.team.editPlayer" />
                                            </Button>
                                        </div>
                                    </div>
                                }
                            />
                        </List.Item>
                    </>
                )}
                className={styles.teams}
                loading={loading}
            />
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { modalVisible, modalChangeVisible } = this.state;

        return (
            <>
                <ProfilePhotoComponent visible={modalVisible} onCancel={() => this.showModal(false)} />
                <ChangeProfileComponent visible={modalChangeVisible} onCancel={() => this.showModalChange(false)} />
                {this.props.display === 'cards' ? this.renderCards() : this.renderList()}
            </>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(TeamComponent);

interface Props extends WrappedComponentProps {
    id?: string;
    team?: Team;
    positionIds?: number[];
    display?: DisplayType;
}

interface State {
    modalVisible?: boolean;
    modalChangeVisible?: boolean;
    players?: Player[];
    team?: Team;
    loading?: boolean;
    searchText?: string;
}
