import { LegacyPage } from '../model/Elements';
import { Player } from '../model/Entities';

class PlayerApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<LegacyPage<Player>> => {
        return Promise.resolve({
            content: players,
            totalElements: 2,
            totalPages: 1,
            size: 2,
            number: 1,
            numberOfElements: 2,
            first: true,
            last: true,
            empty: false,
            pageable: {
                pageNumber: page,
                pageSize: size,
            },
            sort: {
                unsorted: false,
                sorted: true,
                empty: false,
                field: sortField,
                order: sortOrder,
            },
        });
    };

    listByTeam = async (teamId: string | undefined): Promise<Player[]> => {
        return Promise.resolve(players);
    };

    get = async (playerId?: number): Promise<Player> => {
        return players.find((p) => p.id === playerId) || players[0];
    };
}

const playerApi: PlayerApi = new PlayerApi();
export default playerApi;

// mock data
const players: Player[] = [
    {
        id: '1',
        firstName: 'Austin',
        lastName: 'Hake',
    },
    {
        id: '2',
        firstName: 'Carter',
        lastName: 'Butcher',
    },
    {
        id: '3',
        firstName: 'Alex',
        lastName: 'Johnson',
    },
    {
        id: '4',
        firstName: 'Pepe',
        lastName: 'Butcher',
    },
];
