import { LegacyPage } from '../model/Elements';
import { Coach } from '../model/Entities';

class CoachApi {
    list = async (): Promise<Coach[]> => {
        return Promise.resolve(coaches);
    };

    listAll = async (page: number, size: number, sortField: string, sortOrder: boolean): Promise<LegacyPage<Coach>> => {
        return Promise.resolve({
            content: coaches,
            totalElements: 2,
            totalPages: 1,
            size: 2,
            number: 1,
            numberOfElements: 2,
            first: true,
            last: true,
            empty: false,
            pageable: {
                pageNumber: page,
                pageSize: size,
            },
            sort: {
                unsorted: false,
                sorted: true,
                empty: false,
                field: sortField,
                order: sortOrder,
            },
        });
    };

    get = async (coachId?: number): Promise<Coach> => {
        return coaches.find((c) => c.id === coachId) || coaches[0];
    };
}

const coachApi: CoachApi = new CoachApi();
export default coachApi;

// mock data
const coaches: Coach[] = [...Array(4)].map((v, i) => ({
    id: i,
    firstName: `Coach ${i}`,
    lastName: 'Butcher',
}));
