import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import notificationService from '../../../services/NotificationService';

class EventsManagementPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return <div>Events Management</div>;
    };

    render() {
        return <LayoutComponent page="events-management" content={this.renderContent} />;
    }
}
export default injectIntl(EventsManagementPage);

interface Props extends WrappedComponentProps {}

interface State {
    loading?: boolean;
}
