import { LegacyPage } from '../model/Elements';
import { Event } from '../model/Entities';

class EventApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<LegacyPage<Event>> => {
        return Promise.resolve({
            content: events,
            totalElements: 2,
            totalPages: 1,
            size: 2,
            number: 1,
            numberOfElements: 2,
            first: true,
            last: true,
            empty: false,
            pageable: {
                pageNumber: page,
                pageSize: size,
            },
            sort: {
                unsorted: false,
                sorted: true,
                empty: false,
                field: sortField,
                order: sortOrder,
            },
        });
    };

    getEvent = async (eventId: number): Promise<Event> => {
        return events.find((e) => e.id === eventId) || events[0];
    };
}

const eventApi: EventApi = new EventApi();
export default eventApi;

// mock data
const events: Event[] = [
    {
        id: 1,
        name: 'Event 1: Baseball game in San Francisco',
        registrationDates: 'Sep 16, 2022 - Apr 17, 2023',
        groupFees: '$150 per player + Transaction Fee',
        sponsor: 'Gatorade',
    },
    {
        id: 2,
        name: 'Event 2: Softball game in los Ángeles',
        registrationDates: 'Sep 16, 2022 - Apr 17, 2023',
        groupFees: '$150 per player + Transaction Fee',
        sponsor: 'Gatorade',
    },
    {
        id: 3,
        name: 'Event 3: Basebal game in New York',
        registrationDates: 'Sep 16, 2022 - Apr 17, 2023',
        groupFees: '$150 per player + Transaction Fee',
        sponsor: 'Gatorade',
    },
    {
        id: 4,
        name: 'Event 4: Softball game in San Francisco',
        registrationDates: 'Sep 16, 2022 - Apr 17, 2023',
        groupFees: '$150 per player + Transaction Fee',
        sponsor: 'Gatorade',
    },
];
